const actions = {
  GET_TICKETS: "GET_TICKETS",
  GET_TICKETS_SUCCESS: "GET_TICKETS_SUCCESS",
  GET_TICKETS_FAILED: "GET_TICKETS_FAILED",
  SET_TICKETS_STATUS: "SET_TICKETS_STATUS",
  SET_TICKETS_STATUS_SUCCESS: "SET_TICKETS_STATUS_SUCCESS",
  SET_TICKETS_STATUS_FAILED: "SET_TICKETS_STATUS_FAILED",

  getTickets: (payload) => ({
    type: actions.GET_TICKETS,
    payload,
  }),
  setStatus: (payload) => ({
    type: actions.SET_TICKETS_STATUS,
    payload,
  }),
};

export default actions;
