// saga.js
import { all, takeEvery, put, call } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import {
  addBooking,
  assignBooking,
  getAllBookings,
  getBookingsById,
  getRouting,
  scheduleTestBooking,
  updateBooking
} from "./bookingRequests";
import moment from "moment";

function* getAllBookingsEffect({ payload }) {
  if (
    payload.id === 'bookings' ||
    payload.id === 'bookingsHistory' ||
    payload.id === 'preschedule' ||
    payload.id === 'bookingCalendar'
  ) {
    try {
      const response = yield call(getAllBookings, payload.range);
      if (response.status === 200) {
        yield put({
          type: actions.GET_ALL_BOOKINGS_SUCCESS,
          payload: response.data,
        });
        message.success('Successfuly got bookings');
      } else {
        yield put({ type: actions.GET_ALL_BOOKINGS_FAILED });
        message.error('Failed to get bookings');
      }
    } catch (error) {
      yield put({ type: actions.GET_ALL_BOOKINGS_FAILED });
    }
  }
}

function* getBookingsByIdEffect({ payload }) {
  if (payload.id === 'user_bookings_table') {
    try {
      const response = yield call(getBookingsById, payload);
      if (response.status === 200) {
        yield put({
          type: actions.GET_BOOKINGS_BY_ID_SUCCESS,
          payload: response.data,
        });
        message.success('Successfully got bookings');
      } else {
        yield put({ type: actions.GET_BOOKINGS_BY_ID_FAILED });
        message.error('Failed to get bookings');
      }
    } catch (error) {
      yield put({ type: actions.GET_BOOKINGS_BY_ID_FAILED });
    }
  }
}

function* assignBookingEffect({ payload }) {
  try {
    const response = yield call(assignBooking, payload);
    if (response.status === 200) {
      yield put({
        type: actions.ASSIGN_BOOKING_SUCCESS,
        payload: response.data,
      });
      message.success('Successfuly assigned booking');
    } else {
      yield put({ type: actions.ASSIGN_BOOKING_FAILED });
      message.error('Failed to assign booking');
    }
  } catch (error) {
    const { response } = error;
    yield put({ type: actions.ASSIGN_BOOKING_FAILED });
    message.error(response?.data || error?.message);
  }
}

function* getRoutingEffect({ payload }) {
  try {
    const response = yield call(getRouting, payload);
    if (response.status === 200) {
      yield put({
        type: actions.GET_ROUTING_SUCCESS,
        payload: response.data?.data
      });
      message.success("Successfuly got request routing");
    } else {
      yield put({ type: actions.GET_ROUTING_FAILED });
      message.error("Failed to get request routing data");
    }
  } catch (error) {
    yield put({ type: actions.GET_ROUTING_FAILED });
    message.error(error.message);
  }
}

function* updateBookingEffect({ payload }) {
  if (payload.id === 'bookingCalendar') {
    try {
      const response = yield call(updateBooking, payload);
      if (response.status === 200) {
        if (response.data.status) {
          yield put({
            type: actions.UPDATE_BOOKING_SUCCESS,
            payload: response.data.data,
          });
          message.success('Successfully update bookings');
        } else {
          yield put({
            type: actions.UPDATE_BOOKING_FAILED,
            payload: response.data.message,
          });
          message.error(response.data.message || 'Failed to update bookings');
        }
      } else {
        yield put({ type: actions.UPDATE_BOOKING_FAILED });
        message.error('Failed to update bookings');
      }
    } catch (error) {
      yield put({ type: actions.UPDATE_BOOKING_FAILED });
      message.error('Failed to update bookings');
    }
  }
}

function* addBookingEffect({ payload }) {
  if (payload.id === 'bookingCalendar') {
    try {
      const response = yield call(addBooking, payload);
      if (response.status === 200) {
        if (response.data.status) {
          yield put({
            type: actions.ADD_BOOKING_SUCCESS,
            payload: response.data.data,
          });
          message.success('Successfully Add bookings');
        } else {
          yield put({
            type: actions.ADD_BOOKING_FAILED,
            payload: response.data.message,
          });
          message.error(response.data.message || 'Failed to add bookings');
        }
      } else {
        yield put({ type: actions.ADD_BOOKING_FAILED });
        message.error('Failed to add bookings');
      }
    } catch (error) {
      yield put({ type: actions.ADD_BOOKING_FAILED });
      message.error('Failed to add bookings');
    }
  }
}

function* scheduleTestBookingEffect({ payload }) {
  try {
    const { DateRange, ...reqPayload } = payload;
    const response = yield call(scheduleTestBooking, reqPayload);
    if (response.status === 200) {
      if (response.data?.data) {
        if (DateRange?.startDate && DateRange?.endDate) {
          if (
            moment(response.data.data.booking_createdate).isBetween(
              moment(DateRange.startDate),
              moment(DateRange.endDate)
            )
          ) {
            yield put({
              type: actions.SCHEDULE_TEST_BOOKING_SUCCESS,
              payload: [response.data.data]
            });
          } else {
            yield put({
              type: actions.SCHEDULE_TEST_BOOKING_SUCCESS,
              payload: []
            });
          }
        } else {
          yield put({
            type: actions.SCHEDULE_TEST_BOOKING_SUCCESS,
            payload: [response?.data?.data]
          });
        }
        message.success("Successfully schedule test booking");
      } else {
        yield put({
          type: actions.SCHEDULE_TEST_BOOKING_FAILED,
          payload: response?.data?.error
        });
        message.error(
          response?.data?.error || "Failed to schedule test booking"
        );
      }
    } else {
      yield put({ type: actions.SCHEDULE_TEST_BOOKING_FAILED });
      message.error(response?.data?.error || "Failed to schedule test booking");
    }
  } catch (error) {
    const { response } = error;
    message.error(response?.data?.error || "Failed to schedule test booking");
    yield put({ type: actions.SCHEDULE_TEST_BOOKING_FAILED });
  }
}

export default function* bookingSaga() {
  yield all([
    takeEvery(actions.GET_ALL_BOOKINGS, getAllBookingsEffect),
    takeEvery(actions.GET_BOOKINGS_BY_ID, getBookingsByIdEffect),
    takeEvery(actions.ASSIGN_BOOKING, assignBookingEffect),
    takeEvery(actions.GET_ROUTING, getRoutingEffect),
    takeEvery(actions.ADD_BOOKING_START, addBookingEffect),
    takeEvery(actions.UPDATE_BOOKING_START, updateBookingEffect),
    takeEvery(actions.SCHEDULE_TEST_BOOKING_START, scheduleTestBookingEffect)
  ]);
}
