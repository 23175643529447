const actions = {
  GET_STATE_FUND_BOOKING_REPORTS_START: "GET_STATE_FUND_BOOKING_REPORTS_START",
  GET_STATE_FUND_BOOKING_REPORTS_SUCCESS:
    "GET_STATE_FUND_BOOKING_REPORTS_SUCCESS",
  GET_STATE_FUND_BOOKING_REPORTS_FAILED:
    "GET_STATE_FUND_BOOKING_REPORTS_FAILED",
  getStateFundBookingReportsStart: (payload) => ({
    type: actions.GET_STATE_FUND_BOOKING_REPORTS_START,
    payload
  })
};

export default actions;
