import { all, takeEvery, put, call } from "redux-saga/effects";
import stateActions from "./actions";
import { message } from "antd";
import {
  addState,
  updateState,
  deleteState,
  getActiveStates,
  getAllStates
} from "./statesRequests";

function* addStateEffect({ payload }) {
  try {
    const response = yield call(addState, payload);
    if (response.status === 200) {
      message.success("Sucessfully added state");
      yield put({
        type: stateActions.ADD_STATE_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error);
      yield put({
        type: stateActions.ADD_STATE_FAILED,
        payload: response.data
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to add state");
    yield put({ type: stateActions.ADD_STATE_FAILED, payload: response.data });
  }
}

function* getActiveStatesEffect({ payload }) {
  try {
    const response = yield call(getActiveStates, payload);
    if (response.status === 200) {
      message.success("Fetched states data");
      yield put({
        type: stateActions.GET_ACTIVE_STATES_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error);
      yield put({
        type: stateActions.GET_ACTIVE_STATES_FAILED,
        payload: response.data
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to get states");
    yield put({ type: stateActions.GET_STATES_FAILED, payload: response.data });
  }
}

function* updateStateEffect({ payload }) {
  try {
    const response = yield call(updateState, payload);
    if (response.status === 200) {
      message.success("Successfully updated state");
      yield put({
        type: stateActions.UPDATE_STATE_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error);
      yield put({
        type: stateActions.UPDATE_STATE_FAILED,
        payload: response.data
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to update state");
    yield put({
      type: stateActions.UPDATE_STATE_FAILED,
      payload: response.data
    });
  }
}

function* deleteStateEffect({ payload }) {
  try {
    const response = yield call(deleteState, payload);
    if (response.status === 200) {
      message.success("States deleted successfully");
      yield put({
        type: stateActions.DELETE_STATE_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error);
      yield put({
        type: stateActions.DELETE_STATE_FAILED,
        payload: response.data
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to delete states");
    yield put({
      type: stateActions.DELETE_STATE_FAILED,
      payload: response.data
    });
  }
}

function* getAllStatesEffect({ payload }) {
  try {
    const response = yield call(getAllStates, payload);
    if (response.status === 200) {
      message.success("Fetched all states data");
      yield put({
        type: stateActions.GET_ALL_STATES_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error);
      yield put({
        type: stateActions.GET_ALL_STATES_FAILED,
        payload: response.data
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to get states");
    yield put({ type: stateActions.GET_STATES_FAILED, payload: response.data });
  }
}

export default function* statesSaga() {
  yield all([
    takeEvery(stateActions.GET_ACTIVE_STATES_START, getActiveStatesEffect),
    takeEvery(stateActions.GET_ALL_STATES_START, getAllStatesEffect),
    takeEvery(stateActions.UPDATE_STATE_START, updateStateEffect),
    takeEvery(stateActions.ADD_STATE_START, addStateEffect),
    takeEvery(stateActions.DELETE_STATE_START, deleteStateEffect)
  ]);
}
