import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getActiveStates = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_ACTIVE_STATES,
    data: JSON.stringify(payload)
  });
};

export const addState = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.ADD_STATE,
    data: JSON.stringify(payload)
  });
};

export const updateState = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.UPDATE_STATE,
    data: JSON.stringify(payload)
  });
};

export const deleteState = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "DELETE",
    url: apiUrl.DELETE_STATE,
    data: JSON.stringify(payload)
  });
};

export const getAllStates = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_ALL_STATES,
    data: JSON.stringify(payload)
  });
};
