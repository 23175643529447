import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getAllBookings = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_BOOKINGS,
    data: JSON.stringify(payload),
  });
};
export const getBookingsById = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_BOOKINGS_BY_ID,
    data: JSON.stringify(payload),
  });
};

export const addBooking = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.ADD_NEW_BOOKING,
    data: JSON.stringify(payload),
  });
};

export const updateBooking = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.UPDATE_BOOKING,
    data: JSON.stringify(payload),
  });
};

export const assignBooking = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.ASSIGN_BOOKING,
    data: JSON.stringify(payload),
  });
};

export const deleteBooking = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "DELETE",
    url: apiUrl.DELETE_BOOKING,
    data: JSON.stringify(payload),
  });
};
export const getRouting = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_ROUTING,
    data: JSON.stringify(payload),
  });
};

export const scheduleTestBooking = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.SCHEDULE_TEST_BOOKING,
    data: JSON.stringify(payload)
  });
}