const actions = {
  SELECT_DATE: "SELECT_DATE",

  setDefaultDate: (payload) => ({
    type: actions.SELECT_DATE,
    payload,
  }),
};

export default actions;
