import actions from "./actions";

const defaultStates = [
  {
    id: 1,
    value: "Alabama",
    state_code: "AL",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 2,
    value: "Alaska",
    state_code: "AK",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 3,
    value: "Arizona",
    state_code: "AZ",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 4,
    value: "Arkansas",
    state_code: "AR",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 5,
    value: "California",
    state_code: "CA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 6,
    value: "Colorado",
    state_code: "CO",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 7,
    value: "Connecticut",
    state_code: "CT",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 8,
    value: "Delaware",
    state_code: "DE",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 9,
    value: "Florida",
    state_code: "FL",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 10,
    value: "Georgia",
    state_code: "GA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 11,
    value: "Hawaii",
    state_code: "HI",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 12,
    value: "Idaho",
    state_code: "ID",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 13,
    value: "Illinois",
    state_code: "IL",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 14,
    value: "Indiana",
    state_code: "IN",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 15,
    value: "Iowa",
    state_code: "IA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 16,
    value: "Kansas",
    state_code: "KS",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 17,
    value: "Kentucky",
    state_code: "KY",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 18,
    value: "Louisiana",
    state_code: "LA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 19,
    value: "Maine",
    state_code: "ME",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 20,
    value: "Maryland",
    state_code: "MD",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 21,
    value: "Massachusetts",
    state_code: "MA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 22,
    value: "Michigan",
    state_code: "MI",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 23,
    value: "Minnesota",
    state_code: "MN",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 24,
    value: "Mississippi",
    state_code: "MS",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 25,
    value: "Missouri",
    state_code: "MO",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 26,
    value: "Montana",
    state_code: "MT",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 27,
    value: "Nebraska",
    state_code: "NE",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 28,
    value: "Nevada",
    state_code: "NV",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 29,
    value: "New Hampshire",
    state_code: "NH",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 30,
    value: "New Jersey",
    state_code: "NJ",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 31,
    value: "New Mexico",
    state_code: "NM",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 32,
    value: "New York",
    state_code: "NY",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 33,
    value: "North Carolina",
    state_code: "NC",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 34,
    value: "North Dakota",
    state_code: "ND",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 35,
    value: "Ohio",
    state_code: "OH",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 36,
    value: "Oklahoma",
    state_code: "OK",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 37,
    value: "Oregon",
    state_code: "OR",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 38,
    value: "Pennsylvania",
    state_code: "PA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 39,
    value: "Rhode Island",
    state_code: "RI",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 40,
    value: "South Carolina",
    state_code: "SC",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 41,
    value: "South Dakota",
    state_code: "SD",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 42,
    value: "Tennessee",
    state_code: "TN",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 43,
    value: "Texas",
    state_code: "TX",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 44,
    value: "Utah",
    state_code: "UT",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 45,
    value: "Vermont",
    state_code: "VT",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 46,
    value: "Virginia",
    state_code: "VA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 47,
    value: "Washington",
    state_code: "WA",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 48,
    value: "West Virginia",
    state_code: "WV",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 49,
    value: "Wisconsin",
    state_code: "WI",
    state_country_code: "US",
    state_country_name: "United States"
  },
  {
    id: 50,
    value: "Wyoming",
    state_code: "WY",
    state_country_code: "US",
    state_country_name: "United States"
  }
];

const INITIAL_DATA = {
  data: [],
  allStates: [],
  defaultStates: defaultStates,
  error: "",
  loading: false,
  adding: false,
  updating: false,
  deleting: false,
  model: {
    modelVisible: false,
    selectedState: null,
    mode: null
  }
};
export default function stateReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_ACTIVE_STATES_START:
      return {
        ...state,
        loading: true
      };
    case actions.GET_ACTIVE_STATES_SUCCESS:
      return {
        ...state,
        defaultStates: defaultStates.filter((v) => {
          return !action.payload?.data.find((d) => {
            return d.state_code === v.state_code;
          });
        }),
        data: action.payload?.data,
        loading: false,
        error: null
      };
    case actions.GET_ACTIVE_STATES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload?.error
      };
    case actions.UPDATE_STATE_START:
      return {
        ...state,
        updating: true
      };
    case actions.UPDATE_STATE_SUCCESS:
      const updatedField = action.payload?.data;
      return {
        ...state,
        data: state.data.map((d) => {
          if (d.state_id === updatedField.state_id) {
            d = updatedField;
          }
          return d;
        }),
        model: {
          modelVisible: false,
          selectedState: null,
          mode: null
        },
        updating: false,
        error: null
      };
    case actions.UPDATE_STATE_FAILED:
      return {
        ...state,
        updating: false,
        error: action.payload?.error
      };
    case actions.ADD_STATE_START:
      return {
        ...state,
        adding: true
      };
    case actions.ADD_STATE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
        defaultStates: state.defaultStates.filter((v) => {
          return action.payload.data.state_code !== v.state_code;
        }),
        model: {
          modelVisible: false,
          selectedState: null,
          mode: null
        },
        adding: false,
        error: null
      };
    case actions.ADD_STATE_FAILED:
      return {
        ...state,
        adding: false,
        error: action.payload?.error
      };
    case actions.DELETE_STATE_START:
      return {
        ...state,
        deleting: true
      };
    case actions.DELETE_STATE_SUCCESS:
      const newState = state.data.filter(
        (v) => v.state_id !== action.payload.data.state_id
      );
      return {
        ...state,
        data: newState,
        defaultStates: defaultStates.filter((v) => {
          return !newState.find((d) => {
            return d.state_code === v.state_code;
          });
        }),
        deleting: false,
        error: null
      };
    case actions.DELETE_STATE_FAILED:
      return {
        ...state,
        deleting: false,
        error: action.payload?.error
      };
    case actions.SET_STATE_MODEL:
      return {
        ...state,
        model: action.payload
      };
    case actions.GET_ALL_STATES_START:
      return {
        ...state,
        loading: true
      };
    case actions.GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        allStates: action.payload?.data,
        loading: false,
        error: null
      };
    case actions.GET_ALL_STATES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload?.error
      };

    default:
      return state;
  }
}
