import actions from "./actions";

const INITIAL_DATA = {
  data: { payouts: 0 },
  error: null,
  loading: false,
};
export default function (state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.GET_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
