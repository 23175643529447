const actions = {
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_DATA_FAILED: "GET_DASHBOARD_DATA_FAILED",

  getData: (payload) => ({
    type: actions.GET_DASHBOARD_DATA,
    payload, // startDate and endDate
  }),
};

export default actions;
