import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getDataReq = (payload) => {
  console.log(JSON.stringify(payload));
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.DASHBOARD_DATA,
    data: JSON.stringify(payload),
  });
};
