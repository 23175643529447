import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getTicketsRequest = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_TICKETS,
    data: JSON.stringify(payload),
  });
};
export const setTicketStatusRequest = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.SET_TICKET_STATUS,
    data: JSON.stringify(payload),
  });
};
