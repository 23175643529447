// saga.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import { getFaqRequest } from "./faqrequests";
import { addFaq, updateFaqRequest, deleteFaq } from "./faqrequests";
function* getFaqEffect() {
  try {
    const response = yield call(getFaqRequest);
    if (response.status == 200) {
      yield put({
        type: actions.GET_FAQ_SUCCESS,
        payload: response.data,
      });
      message.success("Successfuly got Faq data");
    } else {
      yield put({ type: actions.GET_FAQ_FAILED });
      message.error("Failed to get faq data");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.GET_FAQ_FAILED, payload: error.message });
  }
}
function* addFaqEffect({ payload }) {
  try {
    const response = yield call(addFaq, payload);
    if (response.status === 200) {
      yield put({
        type: actions.ADD_FAQ_SUCCESS,
        payload: response.data,
      });
      message.success("Successfuly added Faq");
    } else {
      yield put({ type: actions.ADD_FAQ_FAILED });
      message.error("Failed to add faq");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.ADD_FAQ_FAILED });
  }
}

function* updateFaqEffect({ payload }) {
  try {
    const response = yield call(updateFaqRequest, payload);
    if (response.status === 200) {
      yield put({
        type: actions.UPDATE_FAQ_SUCCESS,
        payload: response.data,
      });
      message.success("Successfuly updated Faq");
    } else {
      yield put({ type: actions.UPDATE_FAQ_FAILED });
      message.error("Failed to update faq");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.UPDATE_FAQ_FAILED });
  }
}

function* deleteFaqEffect({ payload }) {
  try {
    const response = yield call(deleteFaq, payload);
    if (response.status === 200) {
      yield put({
        type: actions.DELETE_FAQ_SUCCESS,
        payload: payload,
      });

      message.success("Successfuly deleted Faq");
    } else {
      yield put({ type: actions.DELETE_FAQ_FAILED });
      message.error("Failed to delete faq");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.DELETE_FAQ_FAILED });
  }
}

export default function* planSaga() {
  yield all([
    takeEvery(actions.GET_FAQ, getFaqEffect),
    takeEvery(actions.ADD_FAQ, addFaqEffect),
    takeEvery(actions.UPDATE_FAQ, updateFaqEffect),
    takeEvery(actions.DELETE_FAQ, deleteFaqEffect),

    // takeEvery(actions.UPDATE_SETTINGS, updateSettingsEffect),
  ]);
}
