import {
  apiUrl,
  axiosAdminAuthInstanceLocal,
  axiosFileUploadInstance
} from "../axiosInstance";

export const getUser = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_USER,
    data: JSON.stringify(payload)
  });
};

export const getAllUsers = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_ALL_USERS,
    data: JSON.stringify(payload)
  });
};

export const addUser = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.ADD_USER,
    data: JSON.stringify(payload)
  });
};

export const updateUser = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.UPDATE_USER,
    data: JSON.stringify(payload)
  });
};

export const updateUserPic = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.UPDATE_USER_PIC,
    data: JSON.stringify(payload)
  });
};

export const imageUploadRequest = (payload) => {
  const formData = new FormData();
  payload.data.forEach((file) => {
    formData.append("doc", file);
  });
  formData.append("user_type", payload.user_type);
  return axiosFileUploadInstance({
    method: "POST",
    url: `${apiUrl.IMAGE_UPLOAD}`,
    data: formData
  });
};

export const getTestUsers = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_TEST_USERS,
    params: payload
  });
};

export const removeUserNotifications = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "DELETE",
    url: apiUrl.REMOVE_USER_NOTIFICATION,
    data: payload
  });
};
