const actions = {
  GET_BOOKING_SUBJECTS_START: "GET_BOOKING_SUBJECTS_START",
  GET_BOOKING_SUBJECTS_SUCCESS: "GET_BOOKING_SUBJECTS_SUCCESS",
  GET_BOOKING_SUBJECTS_FAILED: "GET_BOOKING_SUBJECTS_FAILED",
  UPDATE_BOOKING_SUBJECT_START: "UPDATE_BOOKING_SUBJECT_START",
  UPDATE_BOOKING_SUBJECT_SUCCESS: "UPDATE_BOOKING_SUBJECT_SUCCESS",
  UPDATE_BOOKING_SUBJECT_FAILED: "UPDATE_BOOKING_SUBJECT_FAILED",
  DELETE_BOOKING_SUBJECT_START: "DELETE_BOOKING_SUBJECT_START",
  DELETE_BOOKING_SUBJECT_SUCCESS: "DELETE_BOOKING_SUBJECT_SUCCESS",
  DELETE_BOOKING_SUBJECT_FAILED: "DELETE_BOOKING_SUBJECT_FAILED",
  ADD_BOOKING_SUBJECT_START: "ADD_BOOKING_SUBJECT_START",
  ADD_BOOKING_SUBJECT_SUCCESS: "ADD_BOOKING_SUBJECT_SUCCESS",
  ADD_BOOKING_SUBJECT_FAILED: "ADD_BOOKING_SUBJECT_FAILED",
  SET_BOOKING_SUBJECT_MODEL: "SET_BOOKING_SUBJECT_MODEL",
  SET_BOOKING_SUBJECT_FILTER: "SET_BOOKING_SUBJECT_FILTER",
  getBookingSubjectsStart: (payload) => ({
    type: actions.GET_BOOKING_SUBJECTS_START,
    payload
  }),
  deleteBookingSubjectStart: (payload) => ({
    type: actions.DELETE_BOOKING_SUBJECT_START,
    payload: payload
  }),
  updateBookingSubjectStart: (payload) => ({
    type: actions.UPDATE_BOOKING_SUBJECT_START,
    payload
  }),
  addBookingSubjectStart: (payload) => ({
    type: actions.ADD_BOOKING_SUBJECT_START,
    payload
  }),
  setBookingSubjectsModel: (payload) => ({
    type: actions.SET_BOOKING_SUBJECT_MODEL,
    payload
  }),
  setBookingSubjectsFilter: (payload) => ({
    type: actions.SET_BOOKING_SUBJECT_FILTER,
    payload
  })
};

export default actions;
