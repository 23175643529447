import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getPlan = () => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_PLANS,
  });
};

export const addPlan = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.ADD_NEW_PLAN,
    data: JSON.stringify(payload),
  });
};

export const updatePlan = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.UPDATE_PLAN,
    data: JSON.stringify(payload),
  });
};

export const deletePlan = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "DELETE",
    url: apiUrl.DELETE_PLAN,
    data: JSON.stringify(payload),
  });
};
