
import actions from './actions';

const INITIAL_DATA = {
  data: [],
  error: null,
  adding:false,
  updating:false,
  deleting:false,
  getting:false,
};
export default function planReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_PLAN_START:
      return {
        ...state,
        getting: true,
      };
    case actions.GET_PLAN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        getting: false,
        error: null,
      };
    case actions.GET_PLAN_FAILED:
      return {
        ...state,
        getting: false,
        error: action.payload,
      };
    case actions.UPDATE_PLAN_START:
      return {
        ...state,
        updating: true,
      };
    case actions.UPDATE_PLAN_SUCCESS:
      const updatedField = action.payload
      const filteredData = state.data.filter((d) => d.plan_id !== updatedField.plan_id);
      return {
        ...state,
        data: [...filteredData, updatedField],
        updating: false,
        error: null,
      };
    case actions.UPDATE_PLAN_FAILED:
      return {
        ...state,
        updating: false,
        error: action.payload,
      };
    case actions.DELETE_PLAN_START:
      return {
        ...state,
        deleting: true,
      };
    case actions.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        data: state.data.filter((d) => d.plan_id !== action.payload.id),
        deleting: false,
        error: null,
      };
    case actions.DELETE_PLAN_FAILED:
      return {
        ...state,
        deleting: false,
        error: action.payload,
      };
    case actions.ADD_PLAN_START:
      return {
        ...state,
        adding: true,
      };
    case actions.ADD_PLAN_SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        adding: false,
        error: null,
      };
    case actions.ADD_PLAN_FAILED:
      return {
        ...state,
        adding: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

