const actions = {
  GET_FAQ: "GET_FAQ",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAILED: "GET_FAQ_FAILED",
  DELETE_FAQ: "DELETE_FAQ",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAILED: "DELETE_FAQ_FAILED",
  ADD_FAQ: "ADD_FAQ",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  ADD_FAQ_FAILED: "ADD_FAQ_FAILED",
  UPDATE_FAQ: "UPDATE_FAQ",
  UPDATE_FAQ_SUCCESS: "UPDATE_FAQ_SUCCESS",
  UPDATE_FAQ_FAILED: "UPDATE_FAQ_FAILED",
  getFaq: () => ({
    type: actions.GET_FAQ,
  }),
  deleteFaq: (payload) => ({
    type: actions.DELETE_FAQ,
    payload,
  }),
  updateFaq: (payload) => ({
    type: actions.UPDATE_FAQ,
    payload,
  }),
  addFaq: (payload) => ({
    type: actions.ADD_FAQ,
    payload,
  }),
  addFaqSucess: (payload) => ({
    type: actions.ADD_FAQ_SUCCESS,
    payload,
  }),
  addFaqFailed: (payload) => ({
    type: actions.ADD_FAQ_SUCCESS,
    payload,
  }),
  updateFaqSucess: (payload) => ({
    type: actions.UPDATE_FAQ_SUCCESS,
    payload,
  }),
  updateFaqFailed: (payload) => ({
    type: actions.UPDATE_FAQ_FAILED,
    payload,
  }),
};

export default actions;
