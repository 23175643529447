import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const sendNotificationRequest = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.SEND_NOTIFICATION,
    data: JSON.stringify(payload),
  });
};

export const getNotificationDataRequest = () => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_NOTIFICATION_DATA,
  });
};
