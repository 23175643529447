import actions from "./actions";
const INITIAL_DATA = {
  data: [],
  error: "",
  loading: false,
  generating: false
};
export default function reportReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_STATE_FUND_BOOKING_REPORTS_START:
      return {
        ...state,
        generating: true
      };
    case actions.GET_STATE_FUND_BOOKING_REPORTS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
        generating: false,
        error: null
      };
    case actions.GET_STATE_FUND_BOOKING_REPORTS_FAILED:
      return {
        ...state,
        generating: false,
        error: action.payload?.error
      };
    default:
      return state;
  }
}
