import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getPaymentRequest = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_PAYMENTS,
    data: JSON.stringify(payload),
  });
};
export const getPayoutRequest = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_PAYOUTS,
    data: JSON.stringify(payload),
  });
};
