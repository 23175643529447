import profileActions from "./actions";

const INITIAL_DATA = {
  data: {},
  loading: true,
  error: null,
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case profileActions.FETCH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case profileActions.FETCH_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case profileActions.UPDATE_PROFILE_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case profileActions.UPLOAD_PROFILE_PIC_START:
      return {
        ...state,
        loading: true,
      };
    case profileActions.UPDATE_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case profileActions.UPDATE_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
