// saga.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import dateActions from "@iso/redux/dateRange/actions";
import { getPaymentRequest, getPayoutRequest } from "./paymentRequest";

function* getPaymentEffect({ payload }) {
  if (payload.id === "payments" || payload.id === "paymentsDashboard") {
    try {
      const response = yield call(getPaymentRequest, payload.range);
      if (response.status == 200) {
        yield put({
          type: actions.GET_PAYMENTS_DATA_SUCCESS,
          payload: response.data,
        });
        message.success("Successfuly got payments data");
      } else {
        yield put({ type: actions.GET_PAYMENTS_DATA_FAILED });
        message.error("Failed to get payments data");
      }
    } catch (error) {
      console.log(error);
      yield put({ type: actions.GET_PAYMENTS_DATA_FAILED });
    }
  }
}

function* getPayoutEffect({ payload }) {
  if (payload.id === "payouts" || payload.id === "payoutsDashboard") {
    try {
      const response = yield call(getPayoutRequest, payload.range);
      if (response.status == 200) {
        yield put({
          type: actions.GET_PAYOUT_DATA_SUCCESS,
          payload: response.data,
        });
        message.success("Successfuly got payout data");
      } else {
        yield put({ type: actions.GET_PAYOUT_DATA_FAILED });
        message.error("Failed to get payouts dada");
      }
    } catch (error) {
      console.log(error);
      yield put({ type: actions.GET_PAYOUT_DATA_FAILED });
    }
  }
}

export default function* planSaga() {
  yield all([
    takeEvery(
      [actions.GET_PAYMENTS_DATA, dateActions.SELECT_DATE],
      getPaymentEffect
    ),
    takeEvery(
      [actions.GET_PAYOUT_DATA, dateActions.SELECT_DATE],
      getPayoutEffect
    ),
  ]);
}
