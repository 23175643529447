import actions from "./actions";

const INITIAL_DATA = {
  payments: [],
  payouts: [],
  error: null,
  loading: false,
};
export default function (state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_PAYMENTS_DATA || actions.GET_PAYOUT_DATA:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PAYMENTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        payments: action.payload,
      };
    case actions.GET_PAYOUT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        payouts: action.payload,
      };
    case actions.GET_PAYMENTS_DATA_FAILED || actions.GET_PAYOUT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
