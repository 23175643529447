import actions from "./actions";

const INITIAL_DATA = {
  data: { rows: [], count: 0 },
  loading: false
};

export default function interpreterAvailabilityLogReducer(
  state = INITIAL_DATA,
  action
) {
  switch (action.type) {
    case actions.GET_INTERPRETER_AVAILABILITY_LOG_START:
      return {
        ...state,
        data: { rows: [], count: 0 },
        loading: true
      };
    case actions.GET_INTERPRETER_AVAILABILITY_LOG_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case actions.GET_INTERPRETER_AVAILABILITY_LOG_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
