import { all, takeEvery, put, call } from "redux-saga/effects";
import { message } from "antd";
import bookingSubjectActions from "./actions";
import {
  addBookingSubject,
  getBookingSubjects,
  updateBookingSubject,
  deleteBookingSubject
} from "./bookingSubjectsRequests";

function* addBookingSubjectEffect({ payload }) {
  try {
    const response = yield call(addBookingSubject, payload);
    if (response.status === 200) {
      message.success("Sucessfully added booking subject");
      yield put({
        type: bookingSubjectActions.ADD_BOOKING_SUBJECT_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error || "Failed to add booking subject");
      yield put({
        type: bookingSubjectActions.ADD_BOOKING_SUBJECT_FAILED
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to add booking subject");
    yield put({
      type: bookingSubjectActions.ADD_BOOKING_SUBJECT_FAILED
    });
  }
}

function* getBookingSubjectEffect({ payload }) {
  try {
    const response = yield call(getBookingSubjects, payload);
    if (response.status === 200) {
      message.success("Fetched booking subjects data");
      yield put({
        type: bookingSubjectActions.GET_BOOKING_SUBJECTS_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error || "Failed to get booking subjects");
      yield put({
        type: bookingSubjectActions.GET_BOOKING_SUBJECTS_FAILED
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to get booking subjects");
    yield put({ type: bookingSubjectActions.GET_BOOKING_SUBJECTS_FAILED });
  }
}

function* updateBookingSubjectEffect({ payload }) {
  try {
    const response = yield call(updateBookingSubject, payload);
    if (response.status === 200) {
      message.success("Successfully updated booking subject");
      yield put({
        type: bookingSubjectActions.UPDATE_BOOKING_SUBJECT_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error || "Failed to update booking subject");
      yield put({
        type: bookingSubjectActions.UPDATE_BOOKING_SUBJECT_FAILED
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to update booking subject");
    yield put({
      type: bookingSubjectActions.UPDATE_BOOKING_SUBJECT_FAILED
    });
  }
}

function* deleteBookingSubjectEffect({ payload }) {
  try {
    const response = yield call(deleteBookingSubject, payload);
    if (response.status === 200) {
      message.success("Booking subject deleted successfully");
      yield put({
        type: bookingSubjectActions.DELETE_BOOKING_SUBJECT_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error || "Failed to delete booking subject");
      yield put({
        type: bookingSubjectActions.DELETE_BOOKING_SUBJECT_FAILED
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to delete booking subject");
    yield put({
      type: bookingSubjectActions.DELETE_BOOKING_SUBJECT_FAILED
    });
  }
}

export default function* bookingSubjectsSaga() {
  yield all([
    takeEvery(
      bookingSubjectActions.GET_BOOKING_SUBJECTS_START,
      getBookingSubjectEffect
    ),
    takeEvery(
      bookingSubjectActions.UPDATE_BOOKING_SUBJECT_START,
      updateBookingSubjectEffect
    ),
    takeEvery(
      bookingSubjectActions.ADD_BOOKING_SUBJECT_START,
      addBookingSubjectEffect
    ),
    takeEvery(
      bookingSubjectActions.DELETE_BOOKING_SUBJECT_START,
      deleteBookingSubjectEffect
    )
  ]);
}
