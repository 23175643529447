const actions = {
  GET_PAYMENTS_DATA: "GET_PAYMENTS_DATA",
  GET_PAYMENTS_DATA_SUCCESS: "GET_PAYMENTS_DATA_SUCCESS",
  GET_PAYMENTS_DATA_FAILED: "GET_PAYMENTS_DATA_FAILED",
  GET_PAYOUT_DATA: "GET_PAYOUT_DATA",
  GET_PAYOUT_DATA_SUCCESS: "GET_PAYOUT_DATA_SUCCESS",
  GET_PAYOUT_DATA_FAILED: "GET_PAYOUT_DATA_FAILED",
  getPayments: (payload) => ({
    type: actions.GET_PAYMENTS_DATA,
    payload,
  }),
  getPayouts: (payload) => ({
    type: actions.GET_PAYOUT_DATA,
    payload,
  }),
};

export default actions;
