import actions from "./actions";

const INITIAL_DATA = {
  selectedUser: {},
  userDocs: [],
  data: [],
  paginationData: { rows: [], totalCount: 0 },
  updating: false,
  updatingStatus: false,
  updatingPayrate: false,
  updatingDeleteStatus: false,
  loading: false,
  loadingPaginationData: false,
  loadingDocs: false,
  editView: false
};
export default function interpreterReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_INTERPRETER_START:
      return {
        ...state,
        loading: true
      };
    case actions.GET_INTERPRETER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null
      };
    case actions.GET_INTERPRETER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.GET_INTERPRETER_DOCS_START:
      return {
        ...state,
        loadingDocs: true
      };
    case actions.GET_INTERPRETER_DOCS_SUCCESS:
      return {
        ...state,
        userDocs: action.payload,
        loadingDocs: false,
        error: null
      };
    case actions.GET_INTERPRETER_DOCS_FAILED:
      return {
        ...state,
        loadingDocs: false,
        error: action.payload
      };
    case actions.UPDATE_INTERPRETER_START:
    case actions.UPDATE_INTERPRETER_PIC_START:
      return {
        ...state,
        updating: true
      };
    case actions.UPDATE_INTERPRETER_SUCCESS:
      const updatedField = action.payload;
      const filteredData = state.data.map((d) => {
        if (d.user_id === updatedField.user_id) {
          return updatedField;
        }
        return d;
      });

      return {
        ...state,
        selectedUser: { ...state.selectedUser, ...updatedField },
        data: [...filteredData],
        updatingStatus: false,
        updatingPayrate: false,
        updating: false,
        editView: false,
        error: null
      };
    case actions.UPDATE_INTERPRETER_FAILED:
      return {
        ...state,
        updating: false,
        updatingStatus: false,
        updatingPayrate: false
      };
    case actions.UPDATE_DELETE_REQUEST_STATUS_START:
      return {
        ...state,
        updatingDeleteStatus: true
      };
    case actions.UPDATE_DELETE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (v) => v.user_id !== action.payload?.data?.user_id
        ),
        updatingDeleteStatus: false,
        error: null
      };
    case actions.UPDATE_DELETE_REQUEST_STATUS_FAILED:
      return {
        ...state,
        updatingDeleteStatus: false,
        error: action.payload
      };
    case actions.ADD_INTERPRETER_START:
      return {
        ...state,
        loading: true
      };
    case actions.ADD_INTERPRETER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case actions.ADD_INTERPRETER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.SET_INTERPRETER_STATUS:
      return {
        ...state,
        updatingStatus: true,
        error: action.payload
      };
    case actions.SET_INTERPRETER_PAYRATE:
      return {
        ...state,
        updatingPayrate: true,
        error: action.payload
      };
    case actions.GET_INTERPRETER_BY_ID_START:
      return {
        ...state,
        selectedUser: {},
        loading: true
      };
    case actions.GET_INTERPRETER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload
      };
    case actions.GET_INTERPRETER_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.SET_INTERPRETER_EDIT_VIEW:
      return {
        ...state,
        editView: action.payload
      };
    case actions.GET_INTERPRETERS_WITH_PAGINATION_START:
      return {
        ...state,
        loadingPaginationData: true
      };
    case actions.GET_INTERPRETERS_WITH_PAGINATION_SUCCESS:
      const isSearchAdded = action.payload.isSearchAdded;
      if (isSearchAdded) {
        return {
          ...state,
          paginationData: {
            rows: action.payload.data.rows,
            totalCount: action.payload.data.count
          },
          loadingPaginationData: false
        };
      } else {
        const mergedInterpreters = [
          ...state.paginationData.rows,
          ...action.payload.data.rows
        ];
        const uniqueUserKeys = new Set();
        const newInterpretersArray = mergedInterpreters.filter((obj) => {
          if (!uniqueUserKeys.has(obj["user_id"])) {
            uniqueUserKeys.add(obj["user_id"]);
            return true;
          }
          return false;
        });

        return {
          ...state,
          paginationData: {
            rows: newInterpretersArray,
            totalCount: action.payload.data.count
          },
          loadingPaginationData: false
        };
      }
    case actions.GET_INTERPRETERS_WITH_PAGINATION_FAILED:
      return {
        ...state,
        loadingPaginationData: false
      };
    case actions.REMOVE_INTERPRETER_NOTIFICATION_START:
      return {
        ...state,
        updating: true
      };
    case actions.REMOVE_INTERPRETER_NOTIFICATION_SUCCESS:
    case actions.REMOVE_INTERPRETER_NOTIFICATION_FAILED:
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
}
