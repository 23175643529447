const actions = {
  GET_SETTINGS: "GET_SETTINGS",
  GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_FAILED: "GET_SETTINGS_FAILED",
  GET_TERMS: "GET_TERMS",
  GET_TERMS_SUCCESS: "GET_TERMS_SUCCESS",
  GET_TERMS_FAILED: "GET_TERMS_FAILED",
  UPDATE_TERMS: "UPDATE_TERMS",
  UPDATE_TERMS_SUCCESS: "UPDATE_TERMS_SUCCESS",
  UPDATE_TERMS_FAILED: "UPDATE_TERMS_FAILED",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_FAILED: "UPDATE_SETTINGS_FAILED",
  getSettings: () => ({
    type: actions.GET_SETTINGS,
  }),
  updateSettings: (payload) => ({
    type: actions.UPDATE_SETTINGS,
    payload,
  }),
  getTerms: () => ({
    type: actions.GET_TERMS,
  }),
  updateTerms: (payload) => ({
    type: actions.UPDATE_TERMS,
    payload,
  }),
};

export default actions;
