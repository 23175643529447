import actions from "./actions";

const INITIAL_DATA = {
  data: [],
  loading: false,
  adding: false,
  updating: false,
  deleting: false,
  filter: {
    subjectUserType: 2
  },
  model: {
    modelVisible: false,
    selectedBookingSubject: null,
    mode: null
  }
};
export default function bookingSubjectReducer(
  bookingSubject = INITIAL_DATA,
  action
) {
  switch (action.type) {
    case actions.GET_BOOKING_SUBJECTS_START:
      return {
        ...bookingSubject,
        loading: true
      };
    case actions.GET_BOOKING_SUBJECTS_SUCCESS:
      return {
        ...bookingSubject,
        data: action.payload?.data,
        loading: false
      };
    case actions.GET_BOOKING_SUBJECTS_FAILED:
      return {
        ...bookingSubject,
        loading: false
      };
    case actions.UPDATE_BOOKING_SUBJECT_START:
      return {
        ...bookingSubject,
        updating: true
      };
    case actions.UPDATE_BOOKING_SUBJECT_SUCCESS:
      const updatedField = action.payload?.data;
      const updatedBookingData = [];
      bookingSubject.data.forEach((d) => {
        if (d.subject_id === updatedField.subject_id) {
          if (d.subject_user_type === updatedField.subject_user_type) {
            updatedBookingData.push(updatedField);
          }
        } else {
          updatedBookingData.push(d);
        }
      });
      return {
        ...bookingSubject,
        data: updatedBookingData,
        model: {
          modelVisible: false,
          selectedBookingSubject: null,
          mode: null
        },
        updating: false
      };
    case actions.UPDATE_BOOKING_SUBJECT_FAILED:
      return {
        ...bookingSubject,
        updating: false
      };
    case actions.ADD_BOOKING_SUBJECT_START:
      return {
        ...bookingSubject,
        adding: true
      };
    case actions.ADD_BOOKING_SUBJECT_SUCCESS:
      const newBookingSubjectData = [...bookingSubject.data];
      if (
        action.payload.data.subject_user_type ===
        bookingSubject.filter.subjectUserType
      ) {
        newBookingSubjectData.push(action.payload.data);
      }
      return {
        ...bookingSubject,
        data: newBookingSubjectData,
        model: {
          modelVisible: false,
          selectedBookingSubject: null,
          mode: null
        },
        adding: false
      };
    case actions.ADD_BOOKING_SUBJECT_FAILED:
      return {
        ...bookingSubject,
        adding: false
      };
    case actions.DELETE_BOOKING_SUBJECT_START:
      return {
        ...bookingSubject,
        deleting: true
      };
    case actions.DELETE_BOOKING_SUBJECT_SUCCESS:
      const newBookingSubject = bookingSubject.data.filter(
        (v) => v.subject_id !== action.payload.data.subject_id
      );
      return {
        ...bookingSubject,
        data: newBookingSubject,
        deleting: false
      };
    case actions.DELETE_BOOKING_SUBJECT_FAILED:
      return {
        ...bookingSubject,
        deleting: false
      };
    case actions.SET_BOOKING_SUBJECT_MODEL:
      return {
        ...bookingSubject,
        model: { ...bookingSubject.model, ...action.payload }
      };
    case actions.SET_BOOKING_SUBJECT_FILTER:
      return {
        ...bookingSubject,
        filter: { ...bookingSubject.filter, ...action.payload }
      };
    default:
      return bookingSubject;
  }
}
