import { all, takeEvery, put, call } from "redux-saga/effects";
import { message } from "antd";
import interpreterActions from "./actions";
import dateActions from "@iso/redux/dateRange/actions";
import {
  getInterpreters,
  updateInterpreter,
  setInterpreterStatus,
  setInterpreterPayrate,
  getInterpreterDocs,
  updateDeleteRequestStatus,
  getInterpreter
} from "./interpreterRequests";
import {
  imageUploadRequest,
  removeUserNotifications,
  updateUserPic
} from "../user/userRequests";

function* setStatusEffect({ payload }) {
  try {
    const response = yield call(setInterpreterStatus, payload);
    if (response.status) {
      yield put({
        type: interpreterActions.UPDATE_INTERPRETER_SUCCESS,
        payload: response.data
      });
      message.success("Updated Successfuly");
    } else {
      yield put({ type: interpreterActions.UPDATE_INTERPRETER_FAILED });
      message.error(response.data);
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data);
    yield put({ type: interpreterActions.UPDATE_INTERPRETER_FAILED });
  }
}

function* setPayrateEffect({ payload }) {
  try {
    const response = yield call(setInterpreterPayrate, payload);
    if (response.status) {
      yield put({
        type: interpreterActions.UPDATE_INTERPRETER_SUCCESS,
        payload: response.data
      });
      message.success("Updated Successfuly");
    } else {
      yield put({ type: interpreterActions.UPDATE_INTERPRETER_FAILED });
      message.error(response.message);
    }
  } catch (error) {
    yield put({ type: interpreterActions.UPDATE_INTERPRETER_FAILED });
  }
}

function* getInterpretersEffect({ payload }) {
  if (payload?.id === "newInterpreters" || payload?.id === "interpreters") {
    try {
      const response = yield call(getInterpreters, {
        ...payload.range,
        userStatus: payload?.userStatus
      });
      if (response.status === 200) {
        yield put({
          type: interpreterActions.GET_INTERPRETER_SUCCESS,
          payload: response?.data?.data?.rows
        });
        message.success("Fetched Interpreter data");
      } else {
        yield put({ type: interpreterActions.GET_INTERPRETER_FAILED });
        message.error(
          response?.data?.error || "Failed to fetch interpreters data"
        );
      }
    } catch (error) {
      const { response } = error;
      message.error(
        response?.data?.error || "Failed to fetch interpreters data"
      );
      yield put({ type: interpreterActions.GET_INTERPRETER_FAILED });
    }
  }
  if (payload === "all") {
    try {
      const response = yield call(getInterpreters);
      if (response.status === 200) {
        yield put({
          type: interpreterActions.GET_INTERPRETER_SUCCESS,
          payload: response?.data?.data?.rows
        });
        message.success("Fetched Interpreter data");
      } else {
        yield put({ type: interpreterActions.GET_INTERPRETER_FAILED });
        message.error(
          response?.data?.error || "Failed to fetch interpreters data"
        );
      }
    } catch (error) {
      const { response } = error;
      message.error(
        response?.data?.error || "Failed to fetch interpreters data"
      );
      yield put({ type: interpreterActions.GET_INTERPRETER_FAILED });
    }
  }
}

function* getInterpreterDocsEffect({ payload }) {
  try {
    const response = yield call(getInterpreterDocs, payload);
    if (response.status) {
      yield put({
        type: interpreterActions.GET_INTERPRETER_DOCS_SUCCESS,
        payload: response.data
      });
      message.success("Fetched Interpreter Document Successfully");
    } else {
      yield put({
        type: interpreterActions.GET_INTERPRETER_DOCS_FAILED,
        payload: "something went wrong"
      });
      message.error(response.message);
    }
  } catch (error) {
    yield put({
      type: interpreterActions.GET_INTERPRETER_DOCS_FAILED,
      payload: "something went wrong"
    });
    message.error(error.message);
  }
}

function* updateInterpreterEffect({ payload }) {
  try {
    const response = yield call(updateInterpreter, payload);
    if (response.status === 200) {
      yield put({
        type: interpreterActions.UPDATE_INTERPRETER_SUCCESS,
        payload: response.data.data
      });
      message.success("Interpreter Updated");
    } else {
      yield put({ type: interpreterActions.UPDATE_INTERPRETER_FAILED });
      message.error(response.data?.error || "Failed to interpreter");
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to update interpreter");
    yield put({ type: interpreterActions.UPDATE_INTERPRETER_FAILED });
  }
}

function* updateDeleteRequestStatusEffect({ payload }) {
  try {
    const response = yield call(updateDeleteRequestStatus, payload);
    if (response.status === 200) {
      message.success("Sucessfully updated delete request status");
      yield put({
        type: interpreterActions.UPDATE_DELETE_REQUEST_STATUS_SUCCESS,
        payload: response.data
      });
    } else {
      message.error(response.data.error);
      yield put({
        type: interpreterActions.UPDATE_DELETE_REQUEST_STATUS_FAILED
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(
      response.data?.error || "Failed to update delete request status"
    );
    yield put({ type: interpreterActions.UPDATE_DELETE_REQUEST_STATUS_FAILED });
  }
}

function* getInterpreterByIdEffect({ payload }) {
  try {
    const response = yield call(getInterpreter, payload);
    if (response.status === 200) {
      yield put({
        type: interpreterActions.GET_INTERPRETER_BY_ID_SUCCESS,
        payload: response.data.data
      });
      message.success("Fetched interpreter data");
    } else {
      yield put({ type: interpreterActions.GET_INTERPRETER_BY_ID_FAILED });
      message.error(response.data.error || "Failed to fetch interpreter data");
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to fetch interpreter data");
    yield put({ type: interpreterActions.GET_INTERPRETER_BY_ID_FAILED });
  }
}

function* uploadProfilePicEffect({ payload }) {
  try {
    const uploadedPicUrl = yield call(imageUploadRequest, {
      data: [payload],
      user_type: 2
    });
    if (uploadedPicUrl.status === 200) {
      const response = yield call(updateUserPic, {
        user_id: payload.user_id,
        user_image: uploadedPicUrl.data[0]
      });
      if (response.status === 200) {
        yield put({
          type: interpreterActions.UPDATE_INTERPRETER_SUCCESS,
          payload: response.data
        });
        message.success("Updated profile successfully");
      } else {
        yield put({
          type: interpreterActions.UPDATE_INTERPRETER_FAILED,
          error: response.message
        });
        message.error(response.data.message);
      }
    } else {
      yield put({
        type: interpreterActions.UPDATE_INTERPRETER_FAILED,
        error: uploadedPicUrl.message
      });
      message.error(uploadedPicUrl.data.message);
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.message || "Failed to update profile image");
    yield put({ type: interpreterActions.UPDATE_INTERPRETER_FAILED });
  }
}

function* getInterpretersWithPaginationEffect({ payload }) {
  try {
    const response = yield call(getInterpreters, payload);
    if (response.status === 200) {
      yield put({
        type: interpreterActions.GET_INTERPRETERS_WITH_PAGINATION_SUCCESS,
        payload: { isSearchAdded: !!payload.search, data: response?.data?.data }
      });
      message.success("Fetched Interpreter data");
    } else {
      yield put({
        type: interpreterActions.GET_INTERPRETERS_WITH_PAGINATION_FAILED
      });
      message.error(
        response?.data?.error || "Failed to fetch interpreters data"
      );
    }
  } catch (error) {
    const { response } = error;
    message.error(response?.data?.error || "Failed to fetch interpreters data");
    yield put({
      type: interpreterActions.GET_INTERPRETERS_WITH_PAGINATION_FAILED
    });
  }
}

function* removeInterpreterNotificationsEffect({ payload }) {
  try {
    const response = yield call(removeUserNotifications, payload);
    if (response.status === 200) {
      yield put({
        type: interpreterActions.REMOVE_INTERPRETER_NOTIFICATION_SUCCESS
      });
      message.success(
        response.data.data > 0
          ? "Successfully remove interpreter notifications"
          : "There are currently no notifications that need to be removed"
      );
    } else {
      yield put({
        type: interpreterActions.REMOVE_INTERPRETER_NOTIFICATION_FAILED
      });
      message.error(
        response?.data?.error || "Failed to remove interpreter notifications"
      );
    }
  } catch (error) {
    const { response } = error;
    message.error(
      response?.data?.error || "Failed to remove interpreter notifications"
    );
    yield put({
      type: interpreterActions.REMOVE_INTERPRETER_NOTIFICATION_FAILED
    });
  }
}

export default function* interpreterSaga() {
  yield all([
    takeEvery(
      [interpreterActions.GET_INTERPRETER_START, dateActions.SELECT_DATE],
      getInterpretersEffect
    ),
    takeEvery(
      interpreterActions.GET_INTERPRETER_DOCS_START,
      getInterpreterDocsEffect
    ),
    takeEvery(
      interpreterActions.UPDATE_INTERPRETER_START,
      updateInterpreterEffect
    ),
    takeEvery(interpreterActions.SET_INTERPRETER_STATUS, setStatusEffect),
    takeEvery(interpreterActions.SET_INTERPRETER_PAYRATE, setPayrateEffect),
    takeEvery(
      interpreterActions.UPDATE_DELETE_REQUEST_STATUS_START,
      updateDeleteRequestStatusEffect
    ),
    takeEvery(
      interpreterActions.GET_INTERPRETER_BY_ID_START,
      getInterpreterByIdEffect
    ),
    takeEvery(
      interpreterActions.UPDATE_INTERPRETER_PIC_START,
      uploadProfilePicEffect
    ),
    takeEvery(
      interpreterActions.GET_INTERPRETERS_WITH_PAGINATION_START,
      getInterpretersWithPaginationEffect
    ),
    takeEvery(
      interpreterActions.REMOVE_INTERPRETER_NOTIFICATION_START,
      removeInterpreterNotificationsEffect
    )
  ]);
}
