const actions = {
  GET_ACTIVE_STATES_START: "GET_ACTIVE_STATES_START",
  GET_ACTIVE_STATES_SUCCESS: "GET_ACTIVE_STATES_SUCCESS",
  GET_ACTIVE_STATES_FAILED: "GET_ACTIVE_STATES_FAILED",
  UPDATE_STATE_START: "UPDATE_STATE_START",
  UPDATE_STATE_SUCCESS: "UPDATE_STATE_SUCCESS",
  UPDATE_STATE_FAILED: "UPDATE_STATE_FAILED",
  DELETE_STATE_START: "DELETE_STATE_START",
  DELETE_STATE_SUCCESS: "DELETE_STATE_SUCCESS",
  DELETE_STATE_FAILED: "DELETE_STATE_FAILED",
  ADD_STATE_START: "ADD_STATE_START",
  ADD_STATE_SUCCESS: "ADD_STATE_SUCCESS",
  ADD_STATE_FAILED: "ADD_STATE_FAILED",
  SET_STATE_MODEL: "SET_STATE_MODEL",
  GET_ALL_STATES_START: "GET_ALL_STATES_START",
  GET_ALL_STATES_SUCCESS: "GET_ALL_STATES_SUCCESS",
  GET_ALL_STATES_FAILED: "GET_ALL_STATES_FAILED",
  getActiveStatesStart: (payload) => ({
    type: actions.GET_ACTIVE_STATES_START,
    payload
  }),
  deleteStateStart: (payload) => ({
    type: actions.DELETE_STATE_START,
    payload: payload
  }),
  updateStateStart: (payload) => ({
    type: actions.UPDATE_STATE_START,
    payload
  }),
  addStateStart: (payload) => ({
    type: actions.ADD_STATE_START,
    payload
  }),
  setStateModel: (payload) => ({ type: actions.SET_STATE_MODEL, payload }),
  getAllStatesStart: (payload) => ({
    type: actions.GET_ALL_STATES_START,
    payload
  })
};

export default actions;
