import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";
export const getInterpreterDocs = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_INTERPRETER_DOCS,
    data: JSON.stringify(payload)
  });
};
export const getInterpreters = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.GET_INTERPRETERS,
    data: JSON.stringify(payload)
  });
};

export const getInterpreter = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: `${apiUrl.GET_INTERPRETER}/${payload.id}`
  });
};

export const updateInterpreter = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.UPDATE_INTERPRETER,
    data: JSON.stringify(payload)
  });
};

export const setInterpreterStatus = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.SET_INTERPRETER_STATUS,
    data: JSON.stringify(payload)
  });
};
export const setInterpreterPayrate = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.SET_PAYRATE,
    data: JSON.stringify(payload)
  });
};

export const updateDeleteRequestStatus = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.UPDATE_DELETE_REQUEST_STATUS,
    data: JSON.stringify(payload)
  });
};
