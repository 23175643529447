// saga.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import planActions from "./actions";
import { getPlan, updatePlan, deletePlan, addPlan } from "./planRequests";

function* addPlanEffect({ payload }) {
  try {
    const response = yield call(addPlan, payload);
    if (response.status) {
      yield put({
        type: planActions.ADD_PLAN_SUCCESS,
        payload: response.data,
      });
      message.success("Successfuly added plan");
    } else {
      yield put({ type: planActions.ADD_PLAN_FAILED });
      message.error("Failed to add plan");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: planActions.ADD_PLAN_FAILED });
  }
}

function* getPlanEffect() {
  try {
    const response = yield call(getPlan);
    if (response.status) {
      yield put({
        type: planActions.GET_PLAN_SUCCESS,
        payload: response.data,
      });
      message.success("Fetched plans");
    } else {
      yield put({ type: planActions.GET_PLAN_FAILED });
      message.error("Unable to fetch plans");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: planActions.GET_PLAN_FAILED });
  }
}

function* updatePlanEffect({ payload }) {
  try {
    const response = yield call(updatePlan, payload);
    console.log(response);
    if (response.status === 200) {
      yield put({
        type: planActions.UPDATE_PLAN_SUCCESS,
        payload: response.data,
      });
      message.success("Plan updated");
    } else {
      yield put({ type: planActions.UPDATE_PLAN_FAILED });
      message.error("Fail to update Plan");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: planActions.UPDATE_PLAN_FAILED });
  }
}

function* deletePlanEffect({ payload }) {
  try {
    const response = yield call(deletePlan, payload);
    if (response.status) {
      yield put({
        type: planActions.DELETE_PLAN_SUCCESS,
        payload: payload,
      });
      message.success(`Deleted plan with id: ${payload.id}`);
    } else {
      yield put({ type: planActions.DELETE_PLAN_FAILED });
      message.error(`Unable to delete plan with id : ${payload.id}`);
    }
  } catch (error) {
    console.log(error);
    message.error(`Unable to delete plan  with id: ${payload.id}`);
    yield put({ type: planActions.DELETE_PLAN_FAILED });
  }
}

export default function* planSaga() {
  yield all([
    takeEvery(planActions.GET_PLAN_START, getPlanEffect),
    takeEvery(planActions.UPDATE_PLAN_START, updatePlanEffect),
    takeEvery(planActions.DELETE_PLAN_START, deletePlanEffect),
    takeEvery(planActions.ADD_PLAN_START, addPlanEffect),
  ]);
}
