import {
  axiosInstanceAdminApi,
  apiUrl,
  axiosAdminAuthInstanceLocal,
} from "../axiosInstance";

export const getSettingsRequest = () => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_SETTINGS,
  });
};

export const getTerms = () => {
  return axiosInstanceAdminApi({
    method: "GET",
    url: apiUrl.GET_TERMS,
  });
};

export const updateTerms = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.UPDATE_TERMS,
    data: JSON.stringify(payload),
  });
};

export const updateSettingsRequest = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.UPDATE_SETTINGS,
    data: JSON.stringify(payload),
  });
};
