// saga.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import { getDataReq } from "./dashboardRequest";

function* getDataEffect({ payload }) {
  try {
    const response = yield call(getDataReq, payload);
    if (response.status == 200) {
      yield put({
        type: actions.GET_DASHBOARD_DATA_SUCCESS,
        payload: response.data,
      });
      message.success("Successfuly got dashboard data");
    } else {
      yield put({ type: actions.GET_DASHBOARD_DATA_FAILED });
      message.error("Failed to get dashboard data");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.GET_DASHBOARD_DATA_FAILEDT });
  }
}

export default function* planSaga() {
  yield all([takeEvery(actions.GET_DASHBOARD_DATA, getDataEffect)]);
}
