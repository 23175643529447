import actions from "./actions";

const INITIAL_DATA = {
  userBookingsData: [],
  routingData: { rows: [], count: 0 },
  data: [],
  error: null,
  loading: false,
  updating: false,
  adding: false,
  modal: {
    view: "Month",
    modalVisible: false,
    selectedData: null,
    mode: null
  },
  showTestBookingModel: false
};
export default function bookingReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_ALL_BOOKINGS:
      return {
        ...state,
        loading: true,
        data: []
      };
    case actions.GET_ALL_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case actions.GET_BOOKINGS_BY_ID:
      return {
        ...state,
        userBookingsData: [],
        loading: true
      };
    case actions.GET_BOOKINGS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userBookingsData: action.payload
      };
    case actions.GET_ROUTING:
      return {
        ...state,
        loading: true,
        routingData: { rows: [], count: 0 }
      };
    case actions.GET_ROUTING_SUCCESS:
      return {
        ...state,
        loading: false,
        routingData: action.payload
      };
    case actions.ASSIGN_BOOKING:
      return {
        ...state,
        loading: true
      };
    case actions.ASSIGN_BOOKING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.ASSIGN_BOOKING_SUCCESS:
      const updatedField = action.payload;
      const newUpdatedData = state.data.map((d) => {
        if (d.booking_id === updatedField.booking_id) {
          return updatedField;
        }
        return d;
      });
      return {
        ...state,
        loading: false,
        data: [...newUpdatedData]
      };
    case actions.GET_ALL_BOOKINGS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.GET_BOOKINGS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.GET_ROUTING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.UPDATE_BOOKING_START:
      return {
        ...state,
        updating: true,
        error: null
      };
    case actions.UPDATE_BOOKING_FAILED:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case actions.UPDATE_BOOKING_SUCCESS:
      const updatedBookingData = action.payload;
      const filteredBookingData = state.data.filter((d) => {
        return (
          parseInt(d.booking_id) !== parseInt(updatedBookingData.booking_id)
        );
      });
      return {
        ...state,
        updating: false,
        error: null,
        modal: {
          ...state.modal,
          modalVisible: false,
          selectedData: null,
          mode: null
        },
        data: [updatedBookingData, ...filteredBookingData]
      };
    case actions.ADD_BOOKING_START:
      return {
        ...state,
        adding: true,
        error: null
      };
    case actions.ADD_BOOKING_FAILED:
      return {
        ...state,
        adding: false,
        error: action.payload
      };
    case actions.ADD_BOOKING_SUCCESS:
      const addBookingData = action.payload;
      return {
        ...state,
        adding: false,
        error: null,
        modal: {
          ...state.modal,
          modalVisible: false,
          selectedData: null,
          mode: null
        },
        data: [...state.data, addBookingData]
      };
    case actions.SET_BOOKING_CALENDAR_MODEL:
      return {
        ...state,
        modal: action.payload
      };
    case actions.SCHEDULE_TEST_BOOKING_START:
      return {
        ...state,
        adding: true
      };
    case actions.SCHEDULE_TEST_BOOKING_FAILED:
      return {
        ...state,
        adding: false
      };
    case actions.SCHEDULE_TEST_BOOKING_SUCCESS:
      return {
        ...state,
        adding: false,
        showTestBookingModel: false,
        data: [...state.data, ...action.payload]
      };
    case actions.SET_SCHEDULE_TEST_BOOKING_MODEL:
      return {
        ...state,
        showTestBookingModel: action.payload
      };
    default:
      return state;
  }
}
