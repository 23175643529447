import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import dashboard from "@iso/redux/dashboard/saga";
import ticketsSaga from "@iso/redux/tickets/saga";
import notificationSaga from "@iso/redux/sendNotifications/saga";
import faq from "@iso/redux/faq/saga";
import userSagas from "@iso/redux/user/saga";
import paymentsSagas from "@iso/redux/payments/saga";
import settingsSagas from "@iso/redux/settings/saga";
import planSagas from "@iso/redux/plan/saga";
import interpretreterSagas from "@iso/redux/interpreter/saga";
import contactSagas from "@iso/redux/contacts/saga";
import invoicesSagas from "@iso/redux/invoice/saga";
import mailSagas from "@iso/redux/mail/saga";
import notesSagas from "@iso/redux/notes/saga";
import todosSagas from "@iso/redux/todos/saga";
import ecommerceSaga from "@iso/redux/ecommerce/saga";
import cardsSagas from "@iso/redux/card/saga";
import chatSagas from "@iso/redux/chat/sagas";
import youtubeSearchSagas from "@iso/redux/youtubeSearch/sagas";
import githubSagas from "@iso/redux/githubSearch/sagas";
import articles from "@iso/redux/articles/sagas";
import investors from "@iso/redux/investors/sagas";
import scrumBoardSaga from "@iso/redux/scrumBoard/saga";
import profileSaga from "@iso/redux/profile/saga";
import quizSaga from "@iso/redux/quiz/saga";
import bookingSaga from "@iso/redux/bookings/saga";
import statesSaga from "@iso/redux/states/saga";
import bookingSubjectsSaga from "@iso/redux/bookingSubjects/saga";
import reportsSaga from "@iso/redux/reports/saga";
import interpreterAvailabilityLogSaga from "@iso/redux/interpreterAvailabilityLog/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    dashboard(),
    notificationSaga(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),
    chatSagas(),
    youtubeSearchSagas(),
    githubSagas(),
    articles(),
    investors(),
    scrumBoardSaga(),
    profileSaga(),
    quizSaga(),
    userSagas(),
    interpretreterSagas(),
    planSagas(),
    bookingSaga(),
    paymentsSagas(),
    settingsSagas(),
    ticketsSaga(),
    faq(),
    statesSaga(),
    bookingSubjectsSaga(),
    reportsSaga(),
    interpreterAvailabilityLogSaga()
  ]);
}
