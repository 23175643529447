import { all, takeEvery, put, call } from "redux-saga/effects";
import FileSaver from "file-saver";
import stateActions from "./actions";
import { message } from "antd";
import { getStateFundBookingReport } from "./reportsRequests";

function* getStateFundBookingReportEffect({ payload }) {
  try {
    const response = yield call(getStateFundBookingReport, payload);
    if (response.status === 200) {
      const fileName = `${payload.stateName} ${payload.timezone} Reports ${payload.startDate} To ${payload.endDate}.xlsx`;
      const blob = response.data;
      yield call(FileSaver.saveAs, blob, fileName);
      yield put({
        type: stateActions.GET_STATE_FUND_BOOKING_REPORTS_SUCCESS
      });
    } else {
      message.error(response.error);
      yield put({
        type: stateActions.GET_STATE_FUND_BOOKING_REPORTS_FAILED
      });
    }
  } catch (error) {
    const { response } = error;
    const errorBlob = new Blob([response.data]);
    const errorText = yield call([errorBlob, errorBlob.text]);
    message.error(errorText || "Failed to get states");
    yield put({
      type: stateActions.GET_STATE_FUND_BOOKING_REPORTS_FAILED,
      payload: response.data
    });
  }
}

export default function* statesSaga() {
  yield all([
    takeEvery(
      stateActions.GET_STATE_FUND_BOOKING_REPORTS_START,
      getStateFundBookingReportEffect
    )
  ]);
}
