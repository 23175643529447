// saga.js
import { all, takeEvery, put, call } from "redux-saga/effects";
import userActions from "./actions";
import dateActions from "@iso/redux/dateRange/actions";
import { message } from "antd";
import {
  getAllUsers,
  updateUser,
  addUser,
  getUser,
  updateUserPic,
  imageUploadRequest,
  getTestUsers,
  removeUserNotifications
} from "./userRequests";

function* addUserEffect({ payload }) {
  try {
    const response = yield call(addUser, payload);
    if (response.status) {
      yield put({
        type: userActions.ADD_USER_SUCCESS,
        payload: response.data
      });
      message.success(response.message);
    } else {
      yield put({ type: userActions.ADD_USER_FAILED });
      message.error(response.message);
    }
  } catch (error) {
    yield put({ type: userActions.ADD_USER_FAILED });
  }
}

function* getUserEffect({ payload }) {
  if (
    payload.id === "newUsers" ||
    payload.id === "users" ||
    payload.id === "interpretersBooking" ||
    payload.id === "bookingCalendar"
  ) {
    try {
      const response = yield call(getAllUsers, {
        ...payload.range,
        showDeleteUser: payload?.showDeleteUser
      });
      if (response.status) {
        yield put({
          type: userActions.GET_USER_SUCCESS,
          payload: response.data
        });
        message.success("Fetched user data");
      } else {
        yield put({ type: userActions.GET_USER_FAILED });
        message.error("Failed to fetch user data");
      }
    } catch (error) {
      yield put({ type: userActions.GET_USER_FAILED });
      message.error("Failed to fetch user data");
    }
  }
}

function* getUserByIdEffect({ payload }) {
  try {
    const response = yield call(getUser, payload);
    if (response.status === 200) {
      yield put({
        type: userActions.GET_USER_BY_ID_SUCCESS,
        payload: response.data.data
      });
      message.success("Fetched user data");
    } else {
      yield put({ type: userActions.GET_USER_BY_ID_FAILED });
      message.error(response.data.error || "Failed to fetch user data");
    }
  } catch (error) {
    yield put({ type: userActions.GET_USER_BY_ID_FAILED });
    message.error("Failed to fetch user data");
  }
}

function* uploadProfilePicEffect({ payload }) {
  try {
    const uploadedPicUrl = yield call(imageUploadRequest, {
      data: [payload],
      user_type: 1
    });
    if (uploadedPicUrl.status === 200) {
      const response = yield call(updateUserPic, {
        user_id: payload.user_id,
        user_image: uploadedPicUrl.data[0]
      });
      if (response.status === 200) {
        yield put({
          type: userActions.UPDATE_USER_SUCCESS,
          payload: response.data
        });
        message.success("Updated profile successfully");
      } else {
        yield put({
          type: userActions.UPDATE_USER_FAILED,
          error: response.message
        });
        message.error(response.data.message);
      }
    } else {
      yield put({
        type: userActions.UPDATE_USER_FAILED,
        error: uploadedPicUrl.message
      });
      message.error(uploadedPicUrl.data.message);
    }
  } catch (error) {
    yield put({ type: userActions.UPDATE_USER_FAILED });
  }
}

function* updateUserEffect({ payload }) {
  try {
    const response = yield call(updateUser, payload);
    if (response.status === 200) {
      const { isNotificationSend, ...updateData } = response.data.data;
      yield put({
        type: userActions.UPDATE_USER_SUCCESS,
        payload: updateData
      });
      message.success("User Updated");
      if (isNotificationSend) {
        message.success("Successfully send notification to user");
      }
      if (isNotificationSend !== undefined && !isNotificationSend) {
        message.error("Failed to send notification to user");
      }
    } else {
      yield put({ type: userActions.UPDATE_USER_FAILED });
      message.error(
        response.data.error ||
          response.data.error?.message ||
          "Failed to update user"
      );
    }
  } catch (error) {
    const { response } = error;
    message.error(
      response.data.error ||
        response.data.error?.message ||
        "Failed to update user"
    );
    yield put({ type: userActions.UPDATE_USER_FAILED });
  }
}

function* getTestUsersEffect({ payload }) {
  try {
    const response = yield call(getTestUsers, payload);
    if (response.status === 200) {
      yield put({
        type: userActions.GET_TEST_USER_SUCCESS,
        payload: { isSearchAdded: !!payload.search, data: response?.data?.data }
      });
      message.success("Fetched test users data");
    } else {
      yield put({ type: userActions.GET_TEST_USER_FAILED });
      message.error(response?.data?.error || "Failed to fetch test users data");
    }
  } catch (error) {
    const { response } = error;
    message.error(response?.data?.error || "Failed to fetch user data");
    yield put({ type: userActions.GET_TEST_USER_FAILED });
  }
}

function* removeUserNotificationsEffect({ payload }) {
  try {
    const response = yield call(removeUserNotifications, payload);
    if (response.status === 200) {
      yield put({
        type: userActions.REMOVE_USER_NOTIFICATION_SUCCESS
      });
      message.success(
        response.data.data > 0
          ? "Successfully remove user notifications"
          : "There are currently no notifications that need to be removed"
      );
    } else {
      yield put({ type: userActions.REMOVE_USER_NOTIFICATION_FAILED });
      message.error(
        response?.data?.error || "Failed to remove user notifications"
      );
    }
  } catch (error) {
    const { response } = error;
    message.error(
      response?.data?.error || "Failed to remove user notifications"
    );
    yield put({ type: userActions.REMOVE_USER_NOTIFICATION_FAILED });
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(
      [userActions.GET_USER_START, dateActions.SELECT_DATE],
      getUserEffect
    ),
    takeEvery([userActions.GET_USER_BY_ID], getUserByIdEffect),
    takeEvery(userActions.UPDATE_USER_START, updateUserEffect),
    takeEvery(userActions.UPDATE_USER_PIC_START, uploadProfilePicEffect),
    takeEvery(userActions.ADD_USER_START, addUserEffect),
    takeEvery(userActions.GET_TEST_USER_START, getTestUsersEffect),
    takeEvery(
      userActions.REMOVE_USER_NOTIFICATION_START,
      removeUserNotificationsEffect
    )
  ]);
}
