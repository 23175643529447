import actions from "./actions";

const INITIAL_DATA = {
  data: [],
  error: null,
  loading: false,
};
export default function (state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_FAQ:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_FAQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.UPDATE_FAQ_SUCCESS:
      let updatedList = state.data.map((x) => {
        if (x.faq_id == action.payload.faq_id) {
          return action.payload;
        }
        return x;
      });

      return {
        ...state,
        loading: false,
        data: updatedList,
      };
    case actions.DELETE_FAQ_SUCCESS:
      let newList = state.data.filter(
        (x) => x.faq_id !== action.payload.faq_id
      );
      return {
        ...state,
        loading: false,
        data: newList,
      };
    case actions.DELETE_FAQ_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.DELETE_FAQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FAQ_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.UPDATE_FAQ_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.ADD_FAQ:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [action.payload, ...state.data],
      };
    case actions.ADD_FAQ_FAILED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
}
