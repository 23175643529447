import actions from "./actions";

const INITIAL_DATA = {
  data: [],
  error: null,
  loading: false,
  updating: false,
};

const updateStatus = (data, updatedField) => {
  const filteredData = data.map((d) => {
    if (d.userhelp_id === updatedField.userhelp_id) {
      d.userhelp_status = updatedField.userhelp_status;
      return d;
    }
    return d;
  });
  return filteredData;
};

export default function (state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_TICKETS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.GET_TICKETS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.SET_TICKETS_STATUS:
      return {
        ...state,
        updating: true,
      };
    case actions.SET_TICKETS_STATUS_SUCCESS:
      const data = updateStatus(state.data, action.payload);
      return {
        ...state,
        updating: false,
        data: data,
      };
    case actions.SET_TICKETS_STATUS_FAILED:
      return {
        ...state,
        updating: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
