import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getFaqRequest = () => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_FAQ,
  });
};
export const deleteFaq = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.DELETE_FAQ,
    data: JSON.stringify(payload),
  });
};
export const addFaq = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.ADD_FAQ,
    data: JSON.stringify(payload),
  });
};
export const updateFaqRequest = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.UPDATE_FAQ,
    data: JSON.stringify(payload),
  });
};

// export const updateSettingsRequest = (payload) => {
//   return axiosAdminAuthInstanceLocal({
//     method: "POST",
//     url: apiUrl.UPDATE_SETTINGS,
//     data: JSON.stringify(payload),
//   });
// };
