const actions = {
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_FAILED: "SEND_NOTIFICATION_FAILED",
  GET_NOTIFICATION_DATA: "GET_NOTIFICATION_DATA",
  GET_NOTIFICATION_DATA_SUCCESS: "GET_NOTIFICATION_DATA_SUCCESS",
  GET_NOTIFICATION_DATA_FAILED: "GET_NOTIFICATION_DATA_FAILED",
  sendNotifications: (payload) => ({
    type: actions.SEND_NOTIFICATION,
    payload,
  }),
  getNotificationData: () => ({
    type: actions.GET_NOTIFICATION_DATA,
  }),
};

export default actions;
