// saga.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import {
  sendNotificationRequest,
  getNotificationDataRequest,
} from "./sendNotificationsRequest";

function* sendNotificationEffect({ payload }) {
  try {
    const response = yield call(sendNotificationRequest, payload);
    if (response.status === 200) {
      yield put({
        type: actions.SEND_NOTIFICATION_SUCCESS,
        payload: response.data,
      });
      message.success("Successfuly delivered notification");
    } else {
      yield put({ type: actions.SEND_NOTIFICATION_FAILED });
      message.error("Failed to send notification");
    }
  } catch (error) {
    console.log(error);
    message.error("Failed to send notification");
    yield put({ type: actions.SEND_NOTIFICATION_FAILED });
  }
}

function* getNotificationDataEffect() {
  try {
    const response = yield call(getNotificationDataRequest);
    if (response.status === 200) {
      yield put({
        type: actions.GET_NOTIFICATION_DATA_SUCCESS,
        payload: response.data[0],
      });
    } else {
      yield put({ type: actions.GET_NOTIFICATION_DATA_FAILED });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.GET_NOTIFICATION_DATA_FAILED });
  }
}

export default function* notificationsSaga() {
  yield all([
    takeEvery(actions.SEND_NOTIFICATION, sendNotificationEffect),
    takeEvery(actions.GET_NOTIFICATION_DATA, getNotificationDataEffect),

    // takeEvery(actions.UPDATE_SETTINGS, updateSettingsEffect),
  ]);
}
