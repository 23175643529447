import axios from "axios";
import { message } from "antd";

const devUserApi = "http://devapi.aslanywhere.com:4000/api/v1";
const prodUserApi = "https://api-prod.aslanywhere.com/api/v1";
const devAdminApi = "http://devapi.aslanywhere.com:3003/api";
const prodAdminApi = "https://api.aslanywhere.com/api";

const baseUserApi =
  process.env.REACT_APP_ENVIRONMENT === "production" ? prodUserApi : devUserApi;
const baseAdminApi =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? prodAdminApi
    : devAdminApi;

// Axios NoAuth Instance
export const axiosFileUploadInstance = axios.create({
  baseURL: baseAdminApi,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const axiosInstanceUserApi = axios.create({
  baseURL: baseUserApi,
  headers: {
    "content-type": "application/json",
    accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const axiosInstanceAdminApi = axios.create({
  baseURL: baseAdminApi,
  headers: {
    "content-type": "application/json",
    accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Axios Auth Instance
export const axiosAdminAuthInstance = axios.create({
  baseURL: baseUserApi,
  headers: {
    "content-type": "application/json",
    accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Axios Auth Instance
export const axiosAdminAuthInstanceLocal = axios.create({
  baseURL: baseAdminApi,
  headers: {
    "content-type": "application/json",
    accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const axiosBlobInstance = axios.create({
  baseURL: baseAdminApi,
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  responseType: "blob"
});

// SECTION - API URLS
export const apiUrl = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgetpassword",
  CHANGE_PASSWORD: "/changepassword",
  LOGOUT: "/logout",
  GET_PROFILE: "/getprofile",
  UPDATE_PROFILE: "/updateprofile",
  GET_ALL_USERS: "users/getallusers",
  GET_USER: "users/getuser",
  UPDATE_USER: "users/updateuser",
  UPDATE_USER_PIC: "users/updateuserpic",
  ADD_USER: "users/adduser",
  GET_TEST_USERS: "users/gettestusers",
  REMOVE_USER_NOTIFICATION: "notification/removeusernotifications",

  GET_INTERPRETERS: "interpreters/getinterpreters",
  GET_INTERPRETER: "interpreters/getinterpreter",
  GET_INTERPRETERS_PENDING_REQ: "interpreters/getpendingrequests",
  GET_INTERPRETER_DOCS: "interpreters/getdocs",
  ADD_INTERPRETER: "interpreters/addinterpreter",
  UPDATE_INTERPRETER: "interpreters/updateinterpreter",
  SET_INTERPRETER_STATUS: "interpreters/setstatus",
  SET_PAYRATE: "interpreters/setpayrate",
  UPDATE_DELETE_REQUEST_STATUS: "interpreters/updatedeleterequeststatus",
  GET_INTERPRETER_AVAILABILITY_LOG: "interpreters/getavailabilitylog",

  GET_PLANS: "plans/getallplans",
  DELETE_PLAN: "plans/deleteplan",
  UPDATE_PLAN: "plans/updateplan",
  ADD_NEW_PLAN: "plans/addplan",

  GET_BOOKINGS: "bookings/getbookings",

  GET_FAQ: "faq/get",
  ADD_FAQ: "faq/add",
  UPDATE_FAQ: "faq/edit",
  DELETE_FAQ: "faq/delete",

  GET_BOOKING_BY_DATE: "bookings/getbookingbydate",
  GET_BOOKINGS_BY_ID: "bookings/getbookingsbyid",
  UPDATE_BOOKING: "bookings/updatebooking",
  ADD_NEW_BOOKING: "bookings/addbooking",
  ASSIGN_BOOKING: "bookings/assignto",
  GET_ROUTING: "bookings/getrouting",
  SCHEDULE_TEST_BOOKING: "bookings/scheduleTestBooking",

  GET_PAYMENTS: "payments/getpayments",
  GET_PAYOUTS: "payments/getpayouts",

  IMAGE_UPLOAD: "/docupload",

  GET_SETTINGS: "settings/getsettings",
  UPDATE_SETTINGS: "settings/updatesettings",
  GET_TERMS: "getterms",
  UPDATE_TERMS: "settings/updateterms",

  GET_TICKETS: "help/getqueries",
  SET_TICKET_STATUS: "help/setstatus",

  DASHBOARD_DATA: "dashboard/getdata",
  UPLOAD_PROFILE_PIC: "imageupload",

  GET_NOTIFICATION_DATA: "notification/getdata",
  SEND_NOTIFICATION: "notification/send",

  GET_ACTIVE_STATES: "states/getactivestates",
  GET_ALL_STATES: "states/getallstates",
  ADD_STATE: "states/addstate",
  UPDATE_STATE: "states/updatestate",
  DELETE_STATE: "states/deletestate",

  GET_STATE_FUND_BOOKING_REPORTS: "reports/statefundbooking",

  GET_BOOKING_SUBJECTS: "bookingsubject/getbookingsubjects",
  ADD_BOOKING_SUBJECT: "bookingsubject/addbookingsubject",
  UPDATE_BOOKING_SUBJECT: "bookingsubject/updatebookingsubject",
  DELETE_BOOKING_SUBJECT: "bookingsubject/deletebookingsubject",
};

// Intrceptors for Axios NoAuth Instance - Response
axiosInstanceUserApi.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    const { data } = error.response;
    if (data && data.message) {
      message.error(data.message);
    }
    return Promise.reject(error);
  }
);

axiosInstanceAdminApi.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    const { data } = error.response;
    if (data && data.message) {
      message.error(data.message);
    }
    return Promise.reject(error);
  }
);

// Intrceptors for Axios auth Instance - for request - for adding token from sesssion storage

axiosAdminAuthInstance.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem("user_token") || sessionStorage.getItem("user_token");
  config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});
axiosAdminAuthInstanceLocal.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem("user_token") || sessionStorage.getItem("user_token");
  config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});
axiosFileUploadInstance.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem("user_token") || sessionStorage.getItem("user_token");
  config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});
axiosBlobInstance.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem("user_token") || sessionStorage.getItem("user_token");
  config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});
