// saga.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import dateActions from "@iso/redux/dateRange/actions";
import actions from "./actions";
import { getTicketsRequest, setTicketStatusRequest } from "./ticketRequests";

function* getTicketsEffect({ payload }) {
  if (payload.id === "closedTickets" || payload.id === "activeTickets") {
    try {
      const response = yield call(getTicketsRequest, payload.range);
      if (response.status == 200) {
        yield put({
          type: actions.GET_TICKETS_SUCCESS,
          payload: response.data,
        });
        message.success("Successfuly got all tickets data");
      } else {
        yield put({ type: actions.GET_TICKETS_FAILED });
        message.error("Failed to get tickets data");
      }
    } catch (error) {
      console.log(error);
      yield put({ type: actions.GET_TICKETS_FAILED });
      message.error(error.message);
    }
  }
}

function* setStatusEffect({ payload }) {
  try {
    const response = yield call(setTicketStatusRequest, payload);
    if (response.status == 200) {
      yield put({
        type: actions.SET_TICKETS_STATUS_SUCCESS,
        payload: response.data,
      });
      message.success("Updated");
    } else {
      yield put({ type: actions.SET_TICKETS_STATUS_FAILED });
      message.error("Failed to Update");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.SET_TICKETS_STATUS_FAILED });
  }
}

export default function* planSaga() {
  yield all([
    takeEvery([actions.GET_TICKETS, dateActions.SELECT_DATE], getTicketsEffect),
    takeEvery(actions.SET_TICKETS_STATUS, setStatusEffect),
  ]);
}
