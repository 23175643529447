// saga.js
import { all, takeEvery, put, call } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import {
  getSettingsRequest,
  updateSettingsRequest,
  getTerms,
  updateTerms,
} from "./settingsRequest";

function* getSettingsEffect() {
  try {
    const response = yield call(getSettingsRequest);
    if (response.status === 200 && response.data?.data?.length) {
      yield put({
        type: actions.GET_SETTINGS_SUCCESS,
        payload: response.data.data[0],
      });
      message.success("Successfully got settings");
    } else {
      yield put({ type: actions.GET_SETTINGS_FAILED });
      message.error(response.data.error || "Failed to get settings data");
    }
  } catch (error) {
    const { response } = error;
    yield put({ type: actions.GET_SETTINGS_FAILED });
    message.error(response.data.error || "Failed to get settings data");

  }
}

function* getTermsEffect() {
  try {
    const response = yield call(getTerms);
    if (response) {
      yield put({
        type: actions.GET_TERMS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({ type: actions.GET_TERMS_FAILED });
      message.error("Failed to get data");
    }
  } catch (error) {
    yield put({ type: actions.GET_TERMS_FAILED });
  }
}
function* updateTermsEffect({ payload }) {
  try {
    const response = yield call(updateTerms, payload);
    if (response) {
      yield put({
        type: actions.UPDATE_TERMS_SUCCESS,
        payload: response.data,
      });
      message.success("Successfully updated");
    } else {
      yield put({ type: actions.UPDATE_TERMS_FAILED });
      message.error("Failed to update");
    }
  } catch (error) {
    console.log(error);
    yield put({ type: actions.UPDATE_TERMS_FAILED });
    message.error("Failed to update");
  }
}

function* updateSettingsEffect({ payload }) {
  try {
    const response = yield call(updateSettingsRequest, payload);
    if (response.status === 200 && response.data?.data) {
      yield put({
        type: actions.UPDATE_SETTINGS_SUCCESS,
        payload: response.data?.data,
      });
      message.success(response.data.error || "Successfully updated settings data");
    } else {
      yield put({ type: actions.UPDATE_SETTINGS_FAILED });
      message.error(response.data.error || "Failed to updated settings data");
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data.error || "Failed to updated settings data");
    yield put({ type: actions.UPDATE_SETTINGS_FAILED });
  }
}

export default function* settingsSaga() {
  yield all([
    takeEvery(actions.GET_SETTINGS, getSettingsEffect),
    takeEvery(actions.UPDATE_SETTINGS, updateSettingsEffect),
    takeEvery(actions.GET_TERMS, getTermsEffect),
    takeEvery(actions.UPDATE_TERMS, updateTermsEffect),
  ]);
}
