import actions from "./actions";

const initialState = {
  usertoken: "",
  error: null,
  attempts: null,
  authorized: false,
  isSubmitting: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        isSubmitting: true,
      };
    case actions.CHANGE_PASSWORD_COMPLETED:
      return {
        ...state,
        isSubmitting: false,
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        isSubmitting: true,
      };
    case actions.FORGOT_PASSWORD_COMPLETED:
      return {
        ...state,
        isSubmitting: false,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...initialState,
        usertoken: action.token,
        authorized: true,
        isSubmitting: false,
      };
    case actions.LOGIN_ERROR:
      return {
        ...initialState,
        //user_loginattempt: action.payload.user_loginattempt,
        error: action.error,
        isSubmitting: false,
        attempts: 5 - action.attempts,
      }
    case actions.CHECK_AUTHORIZATION:
      return {
        ...state,
        usertoken: action.token,
      };
    case actions.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
