const actions = {
  GET_ALL_BOOKINGS: "GET_ALL_BOOKINGS",
  GET_ALL_BOOKINGS_SUCCESS: "GET_ALL_BOOKINGS_SUCCESS",
  GET_ALL_BOOKINGS_FAILED: "GET_ALL_BOOKINGS_FAILED",
  GET_BOOKINGS_BY_ID: "GET_BOOKINGS_BY_ID",
  GET_BOOKINGS_BY_ID_SUCCESS: "GET_BOOKINGS_BY_ID_SUCCESS",
  GET_BOOKINGS_BY_ID_FAILED: "GET_BOOKINGS_BY_ID_FAILED",
  GET_ROUTING: "GET_ROUTING",
  GET_ROUTING_SUCCESS: "GET_ROUTING_SUCCESS",
  GET_ROUTING_FAILED: "GET_ROUTING_FAILED",
  GET_BOOKINGS_BY_DATE: "GET_BOOKINGS_BY_DATE",
  GET_BOOKINGS_BY_DATE_SUCCESS: "GET_BOOKINGS_BY_DATE_SUCCESS",
  GET_BOOKINGS_BY_DATE_FAILED: "GET_BOOKINGS_BY_DATE_FAILED",
  UPDATE_BOOKING_START: "UPDATE_BOOKING_START",
  UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
  UPDATE_BOOKING_FAILED: "UPDATE_BOOKING_FAILED",
  DELETE_BOOKING_START: "DELETE_BOOKING_START",
  DELETE_BOOKING_SUCCESS: "DELETE_BOOKING_SUCCESS",
  DELETE_BOOKING_FAILED: "DELETE_BOOKING_FAILED",
  ADD_BOOKING_START: "ADD_BOOKING_START",
  ADD_BOOKING_SUCCESS: "ADD_BOOKING_SUCCESS",
  ADD_BOOKING_FAILED: "ADD_BOOKING_FAILED",
  ASSIGN_BOOKING: "ASSIGN_BOOKING",
  ASSIGN_BOOKING_SUCCESS: "ASSIGN_BOOKING_SUCCESS",
  ASSIGN_BOOKING_FAILED: "ASSIGN_BOOKING_FAILED",
  SET_BOOKING_CALENDAR_MODEL: "SET_BOOKING_CALENDAR_MODEL",
  SCHEDULE_TEST_BOOKING_START: "SCHEDULE_TEST_BOOKING_START",
  SCHEDULE_TEST_BOOKING_SUCCESS: "SCHEDULE_TEST_BOOKING_SUCCESS",
  SCHEDULE_TEST_BOOKING_FAILED: "SCHEDULE_TEST_BOOKING_FAILED",
  SET_SCHEDULE_TEST_BOOKING_MODEL: "SET_SCHEDULE_TEST_BOOKING_MODEL",

  getAllBookings: (payload) => ({
    type: actions.GET_ALL_BOOKINGS,
    payload,
  }),
  getRouting: (payload) => ({
    type: actions.GET_ROUTING,
    payload,
  }),
  getAllBookingsByDate: (payload) => ({
    type: actions.GET_BOOKINGS_BY_DATE,
    payload,
  }),

  assignBooking: (payload) => ({
    type: actions.ASSIGN_BOOKING,
    payload,
  }),

  updateBookingStart: (payload) => ({
    type: actions.UPDATE_BOOKING_START,
    payload,
  }),
  updateBookingSuccess: (plans) => ({
    type: actions.UPDATE_BOOKING_SUCCESS,
    payload: plans,
  }),
  updateBookingFailed: (error) => ({
    type: actions.UPDATE_BOOKING_FAILED,
    payload: error,
  }),
  deleteBookingStart: (payload) => ({
    type: actions.DELETE_BOOKING_START,
    payload,
  }),
  deleteBookingSuccess: (plans) => ({
    type: actions.DELETE_BOOKING_SUCCESS,
    payload: plans,
  }),
  deleteBookingFailed: (error) => ({
    type: actions.DELETE_BOOKING_FAILED,
    payload: error,
  }),
  addBookingStart: (payload) => ({
    type: actions.ADD_BOOKING_START,
    payload,
  }),
  getBookingsById: (payload) => ({
    type: actions.GET_BOOKINGS_BY_ID,
    payload,
  }),
  setBookingCalendarModel: (payload) => ({
    type: actions.SET_BOOKING_CALENDAR_MODEL,
    payload,
  }),
  scheduleTestBookingStart: (payload) => ({
    type: actions.SCHEDULE_TEST_BOOKING_START,
    payload,
  }),
  setShowTestBookingModel: (payload) => ({
    type: actions.SET_SCHEDULE_TEST_BOOKING_MODEL,
    payload,
  })
};

export default actions;
