import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import DateRange from "@iso/redux/dateRange/reducer";
import NotificationsData from "@iso/redux/sendNotifications/reducer";
import Dashboard from "@iso/redux/dashboard/reducer";
import Faq from "@iso/redux/faq/reducer";
import Tickets from "@iso/redux/tickets/reducer";
import Auth from "@iso/redux/auth/reducer";
import User from "@iso/redux/user/reducer";
import Settings from "@iso/redux/settings/reducer";
import Payments from "@iso/redux/payments/reducer";
import bookings from "@iso/redux/bookings/reducer";
import Interpreter from "@iso/redux/interpreter/reducer";
import Plan from "@iso/redux/plan/reducer";
import Mails from "@iso/redux/mail/reducer";
import Calendar from "@iso/redux/calendar/reducer";
import Box from "@iso/redux/box/reducer";
import Notes from "@iso/redux/notes/reducer";
import Todos from "@iso/redux/todos/reducer";
import Contacts from "@iso/redux/contacts/reducer";
import Cards from "@iso/redux/card/reducer";
import Chat from "@iso/redux/chat/reducers";
import DynamicChartComponent from "@iso/redux/dynamicEchart/reducer";
import Ecommerce from "@iso/redux/ecommerce/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import Invoices from "@iso/redux/invoice/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import YoutubeSearch from "@iso/redux/youtubeSearch/reducers";
import Articles from "@iso/redux/articles/reducers";
import Investors from "@iso/redux/investors/reducers";
import scrumBoard from "@iso/redux/scrumBoard/reducer";
import drawer from "@iso/redux/drawer/reducer";
import modal from "@iso/redux/modal/reducer";
import profile from "@iso/redux/profile/reducer";
import githubSearch from "@iso/redux/githubSearch/reducers";
import quiz from "@iso/redux/quiz/reducer";
import interpreterFilter from "@iso/redux/interpreterFilter/reducer";
import states from "@iso/redux/states/reducer";
import bookingSubjects from "@iso/redux/bookingSubjects/reducer";
import reports from "@iso/redux/reports/reducer";
import interpreterAvailabilityLog from "@iso/redux/interpreterAvailabilityLog/reducer";

export default combineReducers({
  Auth,
  DateRange,
  Dashboard,
  App,
  User,
  Payments,
  Settings,
  NotificationsData,
  Interpreter,
  Plan,
  ThemeSwitcher,
  Faq,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  quiz,
  bookings,
  Tickets,
  interpreterFilter,
  states,
  bookingSubjects,
  reports,
  interpreterAvailabilityLog
});
