// saga.js
import { all, takeEvery, put, call } from "redux-saga/effects";
import profileActions from "./actions";
import { message } from "antd";
import {
  updateUser,
  getUser,
  imageUploadRequest,
  updateUserPic,
} from "@iso/redux/user/userRequests";

function* fetchProfileDataEffect() {
  try {
    const response = yield call(getUser);
    if (response.status === 200) {
      yield put({
        type: profileActions.FETCH_PROFILE_DATA_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({ type: profileActions.FETCH_PROFILE_DATA_FAILURE });
      message.error(response.data?.error || "Failed to fetch profile data");
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data?.error || "Failed to fetch profile data");
    yield put({ type: profileActions.FETCH_PROFILE_DATA_FAILURE });
  }
}

function* uploadProfilePicEffect({ payload }) {
  try {
    const uploadedPicUrl = yield call(imageUploadRequest,  {
      data: [payload],
      user_type: 3
    });
    if (uploadedPicUrl.status === 200) {
      const response = yield call(updateUserPic, {
        user_id: payload.user_id,
        user_image: uploadedPicUrl.data[0],
      });
      console.log(response);
      if (response.status === 200) {
        yield put({
          type: profileActions.UPDATE_PROFILE_DATA_SUCCESS,
          payload: response.data,
        });
        message.success("Updated profile successfully");
      } else {
        yield put({
          type: profileActions.UPDATE_PROFILE_DATA_FAILURE,
          error: response.message,
        });
        message.error(response.data.message);
      }
    } else {
      yield put({
        type: profileActions.UPDATE_PROFILE_DATA_FAILURE,
        error: uploadedPicUrl.message,
      });
      message.error(uploadedPicUrl.data.message);
    }
  } catch (error) {
    console.log(error);
    yield put({ type: profileActions.UPDATE_PROFILE_DATA_FAILURE });
  }
}

function* updateProfileDataEffect({ payload }) {
  try {
    const response = yield call(updateUser, payload);
    if (response.status === 200) {
      yield put({
        type: profileActions.UPDATE_PROFILE_DATA_SUCCESS,
        payload: response.data,
      });
      message.success("Updated profile successfully");
    } else {
      yield put({
        type: profileActions.UPDATE_PROFILE_DATA_FAILURE,
        error: response.message,
      });
      message.error(response.data.message);
    }
  } catch (error) {
    console.log(error);
    yield put({ type: profileActions.UPDATE_PROFILE_DATA_FAILURE });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(profileActions.FETCH_PROFILE_DATA_START, fetchProfileDataEffect),
    takeEvery(profileActions.UPLOAD_PROFILE_PIC_START, uploadProfilePicEffect),
    takeEvery(
      profileActions.UPDATE_PROFILE_DATA_START,
      updateProfileDataEffect
    ),
  ]);
}
