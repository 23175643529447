const actions = {
  GET_INTERPRETER_AVAILABILITY_LOG_START:
    "GET_INTERPRETER_AVAILABILITY_LOG_START",
  GET_INTERPRETER_AVAILABILITY_LOG_SUCCESS:
    "GET_INTERPRETER_AVAILABILITY_LOG_SUCCESS",
  GET_INTERPRETER_AVAILABILITY_LOG_FAILED:
    "GET_INTERPRETER_AVAILABILITY_LOG_FAILED",

  getInterpreterAvailabilityLogStart: (payload) => ({
    type: actions.GET_INTERPRETER_AVAILABILITY_LOG_START,
    payload
  })
};

export default actions;
