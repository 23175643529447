const profileActions = {
  FETCH_PROFILE_DATA_START: "FETCH_PROFILE_DATA_START",
  FETCH_PROFILE_DATA_SUCCESS: "FETCH_PROFILE_DATA_SUCCESS",
  FETCH_PROFILE_DATA_FAILURE: "FETCH_PROFILE_DATA_FAILURE",
  UPLOAD_PROFILE_PIC_START: "UPLOAD_PROFILE_PIC_START",
  UPDATE_PROFILE_DATA_START: "UPDATE_PROFILE_DATA_START",
  UPDATE_PROFILE_DATA_SUCCESS: "UPDATE_PROFILE_DATA_SUCCESS",
  UPDATE_PROFILE_DATA_FAILURE: "UPDATE_PROFILE_DATA_FAILURE",
  SET_PROFILE_DATA: "SET_PROFILE_DATA",
  uploadProfilePic: (payload) => ({
    type: profileActions.UPLOAD_PROFILE_PIC_START,
    payload: payload,
  }),
  fetchProfileDataStart: () => ({
    type: profileActions.FETCH_PROFILE_DATA_START,
  }),
  fetchProfileDataSuccess: (profile) => ({
    type: profileActions.FETCH_PROFILE_DATA_SUCCESS,
    payload: profile,
  }),
  fetchProfileDataFailure: (error) => ({
    type: profileActions.FETCH_PROFILE_DATA_FAILURE,
    payload: error,
  }),
  updateProfileDataStart: (payload) => ({
    type: profileActions.UPDATE_PROFILE_DATA_START,
    payload: payload,
  }),
  updateProfileDataSuccess: (profile) => ({
    type: profileActions.UPDATE_PROFILE_DATA_SUCCESS,
    payload: profile,
  }),
  updateProfileDataFailure: (error) => ({
    type: profileActions.UPDATE_PROFILE_DATA_FAILURE,
    payload: error,
  }),
};

export default profileActions;
