const actions = {
  GET_PLAN_START: "GET_PLAN_START",
  GET_PLAN_SUCCESS: "GET_PLAN_SUCCESS",
  GET_PLAN_FAILED: "GET_PLAN_FAILED",
  UPDATE_PLAN_START: "UPDATE_PLAN_START",
  UPDATE_PLAN_SUCCESS: "UPDATE_PLAN_SUCCESS",
  UPDATE_PLAN_FAILED: "UPDATE_PLAN_FAILED",
  DELETE_PLAN_START: "DELETE_PLAN_START",
  DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
  DELETE_PLAN_FAILED: "DELETE_PLAN_FAILED",
  ADD_PLAN_START: "ADD_PLAN_START",
  ADD_PLAN_SUCCESS: "ADD_PLAN_SUCCESS",
  ADD_PLAN_FAILED: "ADD_PLAN_FAILED",
  getPlanStart: (payload) => ({
    type: actions.GET_PLAN_START,
    payload,
  }),
  getPlanSuccess: (plans) => ({
    type: actions.GET_PLAN_SUCCESS,
    payload: plans,
  }),
  getPlanFailed: (error) => ({
    type: actions.GET_PLAN_FAILED,
    payload: error,
  }),
  updatePlanStart: (payload) => ({
    type: actions.UPDATE_PLAN_START,
    payload,
  }),
  updatePlanSuccess: (plans) => ({
    type: actions.UPDATE_PLAN_SUCCESS,
    payload: plans,
  }),
  updatePlanFailed: (error) => ({
    type: actions.UPDATE_PLAN_FAILED,
    payload: error,
  }),
  deletePlanStart: (payload) => ({
    type: actions.DELETE_PLAN_START,
    payload,
  }),
  deletePlanSuccess: (plans) => ({
    type: actions.DELETE_PLAN_SUCCESS,
    payload: plans,
  }),
  deletePlanFailed: (error) => ({
    type: actions.DELETE_PLAN_FAILED,
    payload: error,
  }),
  addPlanStart: (payload) => ({
    type: actions.ADD_PLAN_START,
    payload,
  }),
};

export default actions;
