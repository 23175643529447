import actions from "./actions";

const INITIAL_DATA = {
  data: {},
  error: null,
  loading: false,
  message: "",
};
export default function (state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.SEND_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_NOTIFICATION_DATA:
      return {
        ...state,
        loading: true,
      };
    case actions.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case actions.GET_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.GET_NOTIFICATION_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.SEND_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
