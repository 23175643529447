import actions from "./actions";

const todaysDate = new Date();
const week = new Date(todaysDate.getTime() - 7 * 24 * 60 * 60 * 1000);
const month = new Date(todaysDate.getTime() - 30 * 24 * 60 * 60 * 1000);

const selectWeek = { startDate: week, endDate: todaysDate };
const selectMonth = { startDate: month, endDate: todaysDate };

const initialState = {
  default: selectWeek,
  users: selectMonth,
  interpreters: selectMonth,
  bookings: selectWeek,
  bookingsHistory: selectMonth,
  preschedule: selectMonth,
  payments: selectMonth,
  payouts: selectMonth,
  closedTickets: selectMonth,
  activeTickets: selectMonth,
  paymentsDashboard: selectWeek,
  payoutsDashboard: selectWeek,
  newUsers: selectWeek,
  newInterpreters: selectWeek,
  requestRouting: selectWeek,
  user_bookings_table: selectMonth,
  interpreter_availability_log: selectWeek
};

export default function dateRangeReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case actions.SELECT_DATE:
      return { ...state, [payload.id]: payload.range };
    default:
      return state;
  }
}
