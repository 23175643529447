const actions = {
  GET_INTERPRETER_START: "GET_INTERPRETER_START",
  GET_INTERPRETER_SUCCESS: "GET_INTERPRETER_SUCCESS",
  GET_INTERPRETER_FAILED: "GET_INTERPRETER_FAILED",
  GET_INTERPRETER_DOCS_START: "GET_INTERPRETER_DOCS_START",
  GET_INTERPRETER_DOCS_SUCCESS: "GET_INTERPRETER_DOCS_SUCCESS",
  GET_INTERPRETER_DOCS_FAILED: "GET_INTERPRETER_DOCS_FAILED",
  UPDATE_INTERPRETER_START: "UPDATE_INTERPRETER_START",
  UPDATE_INTERPRETER_SUCCESS: "UPDATE_INTERPRETER_SUCCESS",
  UPDATE_INTERPRETER_FAILED: "UPDATE_INTERPRETER_FAILED",
  DELETE_INTERPRETER_START: "DELETE_INTERPRETER_START",
  DELETE_INTERPRETER_SUCCESS: "DELETE_INTERPRETER_SUCCESS",
  DELETE_INTERPRETER_FAILED: "DELETE_INTERPRETER_FAILED",
  ADD_INTERPRETER_START: "ADD_INTERPRETER_START",
  ADD_INTERPRETER_SUCCESS: "ADD_INTERPRETER_SUCCESS",
  ADD_INTERPRETER_FAILED: "ADD_INTERPRETER_FAILED",
  SET_INTERPRETER_STATUS: "SET_INTERPRETER_STATUS",
  SET_INTERPRETER_PAYRATE: "SET_INTERPRETER_PAYRATE",
  UPDATE_DELETE_REQUEST_STATUS_START: "UPDATE_DELETE_REQUEST_STATUS_START",
  UPDATE_DELETE_REQUEST_STATUS_SUCCESS: "UPDATE_DELETE_REQUEST_STATUS_SUCCESS",
  UPDATE_DELETE_REQUEST_STATUS_FAILED: "UPDATE_DELETE_REQUEST_STATUS_FAILED",
  GET_INTERPRETER_BY_ID_START: "GET_INTERPRETER_BY_ID_START",
  GET_INTERPRETER_BY_ID_SUCCESS: "GET_INTERPRETER_BY_ID_SUCCESS",
  GET_INTERPRETER_BY_ID_FAILED: "GET_INTERPRETER_BY_ID_FAILED",
  SET_INTERPRETER_EDIT_VIEW: "SET_INTERPRETER_EDIT_VIEW",
  UPDATE_INTERPRETER_PIC_START: "UPDATE_INTERPRETER_PIC_START",
  GET_INTERPRETERS_WITH_PAGINATION_START:
    "GET_INTERPRETER_WITH_PAGINATION_START",
  GET_INTERPRETERS_WITH_PAGINATION_SUCCESS:
    "GET_INTERPRETER_WITH_PAGINATION_SUCCESS",
  GET_INTERPRETERS_WITH_PAGINATION_FAILED:
    "GET_INTERPRETER_WITH_PAGINATION_FAILED",
  REMOVE_INTERPRETER_NOTIFICATION_START:
    "REMOVE_INTERPRETER_NOTIFICATION_START",
  REMOVE_INTERPRETER_NOTIFICATION_SUCCESS:
    "REMOVE_INTERPRETER_NOTIFICATION_SUCCESS",
  REMOVE_INTERPRETER_NOTIFICATION_FAILED:
    "REMOVE_INTERPRETER_NOTIFICATION_FAILED",
  getInterpreterStart: (payload) => ({
    type: actions.GET_INTERPRETER_START,
    payload
  }),
  getInterpreterDocs: (payload) => ({
    type: actions.GET_INTERPRETER_DOCS_START,
    payload: payload
  }),
  setStatus: (payload) => ({
    type: actions.SET_INTERPRETER_STATUS,
    payload: payload
  }),
  setPayrate: (payload) => ({
    type: actions.SET_INTERPRETER_PAYRATE,
    payload: payload
  }),
  updateInterpreterStart: (payload) => ({
    type: actions.UPDATE_INTERPRETER_START,
    payload
  }),
  updateDeleteRequestStatusStart: (payload) => ({
    type: actions.UPDATE_DELETE_REQUEST_STATUS_START,
    payload
  }),
  addInterpreterStart: (payload) => ({
    type: actions.ADD_INTERPRETER_START,
    payload
  }),
  getInterpreterById: (payload) => ({
    type: actions.GET_INTERPRETER_BY_ID_START,
    payload
  }),
  setInterpreterEditView: (payload) => ({
    type: actions.SET_INTERPRETER_EDIT_VIEW,
    payload: payload
  }),
  updateInterpreterPic: (payload) => ({
    type: actions.UPDATE_INTERPRETER_PIC_START,
    payload
  }),
  getInterpreterWithPaginationStart: (payload) => ({
    type: actions.GET_INTERPRETERS_WITH_PAGINATION_START,
    payload
  }),
  removeInterpreterNotificationStart: (payload) => ({
    type: actions.REMOVE_INTERPRETER_NOTIFICATION_START,
    payload
  })
};

export default actions;
