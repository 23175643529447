import actions from './actions';

const initialState = {
  default: 'All',
  bookingCalendar: 'All',
};

export default function interpreterFilterReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_INTERPRETER:
      return { ...state, [action.payload.id]: action.payload.interpreterId };

    default:
      return state;
  }
}
