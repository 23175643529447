const actions = {
  GET_USER_START: "GET_USER_START",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILED: "GET_USER_FAILED",
  GET_USER_BY_ID: "GET_USER_BY_ID",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILED: "GET_USER_BY_ID_FAILED",
  UPDATE_USER_PIC_START: "UPDATE_USER_PIC_START",
  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILED: "DELETE_USER_FAILED",
  ADD_USER_START: "ADD_USER_START",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILED: "ADD_USER_FAILED",
  SET_USER_EDIT_VIEW: "SET_USER_EDIT_VIEW",
  GET_TEST_USER_START: "GET_TEST_USER_START",
  GET_TEST_USER_SUCCESS: "GET_TEST_USER_SUCCESS",
  GET_TEST_USER_FAILED: "GET_TEST_USER_FAILED",
  REMOVE_USER_NOTIFICATION_START: "REMOVE_USER_NOTIFICATION_START",
  REMOVE_USER_NOTIFICATION_SUCCESS: "REMOVE_USER_NOTIFICATION_SUCCESS",
  REMOVE_USER_NOTIFICATION_FAILED: "REMOVE_USER_NOTIFICATION_FAILED",
  updateUserPic: (payload) => ({
    type: actions.UPDATE_USER_PIC_START,
    payload
  }),
  getUserStart: (payload) => ({
    type: actions.GET_USER_START,
    payload
  }),
  getUserById: (payload) => ({
    type: actions.GET_USER_BY_ID,
    payload
  }),
  getUserSuccess: (payload) => ({
    type: actions.GET_USER_SUCCESS,
    payload: payload
  }),
  getUserFailed: (error) => ({
    type: actions.GET_USER_FAILED,
    payload: error
  }),
  updateUserStart: (payload) => ({
    type: actions.UPDATE_USER_START,
    payload
  }),
  updateUserSuccess: (payload) => ({
    type: actions.UPDATE_USER_SUCCESS,
    payload: payload
  }),
  updateUserFailed: (error) => ({
    type: actions.UPDATE_USER_FAILED,
    payload: error
  }),
  addUserStart: () => ({
    type: actions.ADD_USER_START
  }),
  addUserSuccess: (payload) => ({
    type: actions.ADD_USER_SUCCESS,
    payload: payload
  }),
  addUserFailed: (error) => ({
    type: actions.ADD_USER_FAILED,
    payload: error
  }),
  setUserEditView: (payload) => ({
    type: actions.SET_USER_EDIT_VIEW,
    payload: payload
  }),
  getTestUserStart: (payload) => ({
    type: actions.GET_TEST_USER_START,
    payload
  }),
  removeUserNotificationStart: (payload) => ({
    type: actions.REMOVE_USER_NOTIFICATION_START,
    payload
  })
};

export default actions;
