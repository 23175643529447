import { all, takeEvery, put, call } from "redux-saga/effects";
import { message } from "antd";
import interpreterAvailabilityLogActions from "./actions";
import { getInterpreterAvailabilityLog } from "./interpreterAvailabilityLogRequests";

function* getInterpreterAvailabilityLogEffect({ payload }) {
  try {
    const response = yield call(getInterpreterAvailabilityLog, payload);
    if (response.status) {
      yield put({
        type: interpreterAvailabilityLogActions.GET_INTERPRETER_AVAILABILITY_LOG_SUCCESS,
        payload: response.data.data
      });
      message.success("Fetched Interpreter Availability History Data");
    } else {
      message.error(response.data.error);
      yield put({
        type: interpreterAvailabilityLogActions.GET_INTERPRETER_AVAILABILITY_LOG_FAILED
      });
    }
  } catch (error) {
    const { response } = error;
    message.error(response.data.error);
    yield put({
      type: interpreterAvailabilityLogActions.GET_INTERPRETER_AVAILABILITY_LOG_FAILED
    });
  }
}

export default function* interpreterAvailabilityLogSaga() {
  yield all([
    takeEvery(
      [
        interpreterAvailabilityLogActions.GET_INTERPRETER_AVAILABILITY_LOG_START
      ],
      getInterpreterAvailabilityLogEffect
    )
  ]);
}
