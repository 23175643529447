import { apiUrl, axiosAdminAuthInstanceLocal } from "../axiosInstance";

export const getBookingSubjects = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "GET",
    url: apiUrl.GET_BOOKING_SUBJECTS,
    params: {
      ...payload
    }
  });
};

export const addBookingSubject = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "POST",
    url: apiUrl.ADD_BOOKING_SUBJECT,
    data: JSON.stringify(payload)
  });
};

export const updateBookingSubject = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "PUT",
    url: apiUrl.UPDATE_BOOKING_SUBJECT,
    data: JSON.stringify(payload)
  });
};

export const deleteBookingSubject = (payload) => {
  return axiosAdminAuthInstanceLocal({
    method: "DELETE",
    url: apiUrl.DELETE_BOOKING_SUBJECT,
    data: JSON.stringify(payload)
  });
};
