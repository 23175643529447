import { axiosInstanceUserApi, apiUrl } from "../axiosInstance";

// Login
export const login = (payload) => {
  payload.type = 3;
  return axiosInstanceUserApi({
    method: "POST",
    url: `${apiUrl.LOGIN}`,
    data: JSON.stringify(payload),
  });
};

// Forgot Password
export const forgotpassword = (payload) => {
  return axiosInstanceUserApi({
    method: "POST",
    url: `${apiUrl.FORGOT_PASSWORD}`,
    data: JSON.stringify(payload),
  });
};

// Change Password
export const changePassword = (payload) => {
  return axiosInstanceUserApi({
    method: "POST",
    url: `${apiUrl.CHANGE_PASSWORD}`,
    data: JSON.stringify(payload),
  });
};
