import actions from "./actions";

const INITIAL_DATA = {
  selectedUser: {},
  data: [],
  testUsers: { rows: [], totalCount: 0 },
  error: "",
  loading: false,
  adding: false,
  updating: false,
  deleting: false,
  getting: false,
  editView: false
};
export default function userReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.GET_USER_START:
      return {
        ...state,
        loading: true
      };
    case actions.GET_USER_BY_ID:
      return {
        ...state,
        selectedUser: {},
        loading: true
      };
    case actions.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload
      };
    case actions.GET_USER_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        getting: false,
        error: null
      };
    case actions.GET_USER_FAILED:
      return {
        ...state,
        loading: false,
        getting: false,
        error: action.payload
      };
    case actions.UPDATE_USER_START:
      return {
        ...state,
        updating: true
      };
    case actions.UPDATE_USER_PIC_START:
      return {
        ...state,
        updating: true
      };
    case actions.UPDATE_USER_SUCCESS:
      const updatedField = action.payload;
      const filteredData = state.data.filter(
        (d) => d.user_id !== updatedField.user_id
      );
      return {
        ...state,
        editView: false,
        selectedUser: { ...state.selectedUser, ...updatedField },
        data: [...filteredData, updatedField],
        updating: false,
        error: null
      };
    case actions.UPDATE_USER_FAILED:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case actions.ADD_USER_START:
      return {
        ...state,
        adding: true
      };
    case actions.ADD_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        adding: false,
        error: null
      };
    case actions.ADD_USER_FAILED:
      return {
        ...state,
        adding: false,
        error: action.payload
      };
    case actions.SET_USER_EDIT_VIEW:
      return {
        ...state,
        editView: action.payload
      };
    case actions.GET_TEST_USER_START:
      return {
        ...state,
        loading: true
      };
    case actions.GET_TEST_USER_SUCCESS:
      const isSearchAdded = action.payload.isSearchAdded;

      if (isSearchAdded) {
        return {
          ...state,
          testUsers: {
            rows: action.payload.data.rows,
            totalCount: action.payload.data.count
          },
          loading: false
        };
      } else {
        const mergedUsers = [
          ...state.testUsers.rows,
          ...action.payload.data.rows
        ];
        const uniqueUserKeys = new Set();
        const newUsersArray = mergedUsers.filter((obj) => {
          if (!uniqueUserKeys.has(obj["user_id"])) {
            uniqueUserKeys.add(obj["user_id"]);
            return true;
          }
          return false;
        });

        return {
          ...state,
          testUsers: {
            rows: newUsersArray,
            totalCount: action.payload.data.count
          },
          loading: false
        };
      }
    case actions.GET_TEST_USER_FAILED:
      return {
        ...state,
        loading: false
      };
    case actions.REMOVE_USER_NOTIFICATION_START:
      return {
        ...state,
        updating: true
      };
    case actions.REMOVE_USER_NOTIFICATION_SUCCESS:
    case actions.REMOVE_USER_NOTIFICATION_FAILED:
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
}
